import { Typography } from '@neui/styleguide-commerzbank';

import { Link } from '@components/neui-components/atoms/Link';

import { WarningScreen } from './WarningScreen';
import {
  selectChatActions,
  selectChatProps,
  useChatStore,
} from '../store/store';
import BeneSVG from './BeneSVG';
import { startChat } from '../utils/utils';

export function DatenschutzScreen() {
  const { setUser, setChatState } = useChatStore(selectChatActions);
  const { userState, chatState } = useChatStore(selectChatProps);
  return (
    <WarningScreen
      // imgSrc="/icons/bot-chat.svg"
      imgAlt="Bene Chatbot"
      headline="Hallo! Ich bin Bene"
      buttonLabel="Verstanden"
      imgComponent={<BeneSVG />}
      onButtonClick={async () => {
        setUser({ hasConsented: true });
        await startChat({
          setChatState,
          setUser,
          chatState,
        });
      }}
    >
      <Typography size={6} textAlign={'center'}>
        Bevor Sie den Commerzbank Chat nutzen, bitte beachten Sie unsere{' '}
        <Link
          href="https://www.commerzbank.de/hinweise/rechtliche-hinweise/#datenschutzhinweise-zur-chatnutzung"
          underlined
          target="_blank"
        >
          Datenschutzbestimmungen
        </Link>
        .
      </Typography>
    </WarningScreen>
  );
}
