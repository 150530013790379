import { AsProps, css, CSSProps, styled, VariantProps } from '@neui/core';
import { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';
import {
  Icon,
  interaction_arrows_arrowRight,
  Typography,
} from '@neui/styleguide-commerzbank';
import { Box, HStack } from '@neui/layout';

import { IconLink, Wrapper } from './IconLink';

export type ClickListProps = AsProps &
  VariantProps<typeof buttonStyles> &
  CSSProps &
  HTMLAttributes<HTMLButtonElement> &
  PropsWithChildren<{
    href?: string;
    iconText?: string;
    disabled?: boolean;
  }>;

const Overlay = styled(Box, {
  transition: 'background-color 300ms ease-in-out',
  content: "''",
  position: 'absolute',
  backgroundColor: '#00',
  zIndex: 0,
  borderRadius: '$space$component-4',
  height: '100%',
  top: 0,
  bottom: 0,
  left: 'calc($space$component-4 * -1)',
  right: 'calc($space$component-4 * -1)',
});

const IconWrapper = styled(Box, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: 24,
  right: 0,
  top: 0,
  bottom: 0,
});

const StyledIcon = styled(Icon, {
  zIndex: 1,
  transition: 'transform 300ms ease-in-out',
  transform: 'translateX(0px)',
});

const ContentContainer = styled(HStack, {
  gap: 24,
  justifyContent: 'space-between',
  width: '100%',
  paddingY: '$component-4',
});

const buttonStyles = css({
  cursor: 'auto',
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  border: 'none',
  background: 'none',
  [`${Wrapper}`]: {
    marginTop: 10,
  },
  variants: {
    underlined: {
      true: {
        [`&:not(:last-child) > ${ContentContainer}`]: {
          borderBottom: '1px solid $petrol200',
        },
      },
    },
    disabled: {
      true: {
        cursor: 'default',
        [`${Wrapper}`]: {
          cursor: 'default',
        },
        '& span, & path': {
          color: '$text-disabled',
          fill: '$text-disabled',
          [`${Wrapper}`]: {
            path: {
              fill: '$text-disabled',
            },
          },
        },
      },
      false: {
        cursor: 'pointer',
        '&:focus-visible': {
          outline: 'none',
          [`& ${Overlay}`]: {
            outline: '2px solid $text-standard',
          },
          [`& ${StyledIcon}`]: {
            transform: 'translateX(8px)',
          },
          [`${Wrapper}`]: {
            transform: 'translateX(5px)',
          },
        },
        '&:hover': {
          [`& ${Overlay}`]: {
            backgroundColor: '$background-hover',
          },
          [`& ${StyledIcon}`]: {
            transform: 'translateX(8px)',
          },
          [`${Wrapper}`]: {
            transform: 'translateX(5px)',
          },
        },
      },
    },
  },
});

const TextContainer = styled(Box, {
  zIndex: 1,
  maxWidth: 720,
});

const ContentIconLink = {
  flexDirection: 'column !important',
  gap: 4,
  [`${Wrapper}`]: {
    marginLeft: '$layout-6',
  },
  // Note: Styling doesn't work with any of out current breakpoints, that's why I added this
  '@media(min-width: 631px)': {
    gap: '$layout-5',
    flexDirection: 'row !important',
    [`${Wrapper}`]: {
      marginLeft: 0,
    },
  },
};

export const ClickItem = forwardRef<HTMLButtonElement, ClickListProps>(
  (
    {
      href,
      renderAs: Component = href ? 'a' : 'button',
      underlined = 'true',
      iconText,
      disabled = false,
      children,
      ...rest
    },
    forwardedRef,
  ) => {
    const StyledComponent = styled(Component, buttonStyles);
    return (
      <StyledComponent
        {...rest}
        href={disabled ? undefined : href}
        ref={forwardedRef}
        underlined={underlined}
        disabled={disabled}
        tabIndex={disabled ? -1 : 0}
      >
        <ContentContainer
          spacing={'$layout-5'}
          justifyContent={'space-between'}
          css={iconText ? { ...ContentIconLink } : undefined}
        >
          <Overlay />
          <TextContainer>{children}</TextContainer>
          {iconText ? (
            <IconLink
              renderAs={Typography}
              iconPosition="right"
              icon={interaction_arrows_arrowRight}
              animationDirection="none"
              css={{
                minWidth: 'fit-content',
              }}
            >
              {iconText}
            </IconLink>
          ) : (
            <IconWrapper>
              <StyledIcon size="small" icon="interaction_arrows_chevronRight" />
            </IconWrapper>
          )}
        </ContentContainer>
      </StyledComponent>
    );
  },
);
ClickItem.displayName = 'ClickItem';
