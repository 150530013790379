import { styled } from '@neui/core';
import { HStack, VStack } from '@neui/layout';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { Pagination } from '@components/neui-components/molecules/Pagination';
import { Section } from '@components/neui-components/atoms/Section';
import { DeviceTypes } from '@utils/MobileChecker';
import { MostReadArticleType } from 'pages';
import { createSearchEntity, useTracker } from '@utils/snowplowTracking';
import {
  CdsSearchContainer,
  CdsSearchOverlay,
} from '@components/CdsStyledComponents';
import {
  GA4SearchType,
  GA4TrackInternalSearch,
  GA4TrackSearchResultLoad,
} from '@utils/tracking';
import { useIsPuk } from '@utils/isPukBoolean';

import { CdsSearch } from './CdsSearch';
import { CdsSearchResultsWrapper } from './CdsSearchResultsWrapper';
import { SuggestionResultType } from './types';
import {
  fetchResults,
  getSearchParameters,
  getUrlParameter,
  updateSearchParameters,
} from './helpers';

export type CdsSearchAndResultsProps = {
  isSearchPage: boolean;
  searchApiUrl: string;
  mostSearchedTerms?: string[];
  inputRef: RefObject<HTMLInputElement>;
  mobileOs: DeviceTypes;
  isNachKon?: boolean;
  isNachKonDe?: boolean;
  mostReadArticles: MostReadArticleType[];
  setOpenSearch: (openSearch: () => void) => void;
  setCloseSearch: (closeSearch: () => void) => void;
  onHasResults: (hasResults: boolean) => void;
  nachKonPortalUrl: string;
  isDE: boolean;
};

const RESULTS_PER_PAGE = 10;

export function CdsSearchAndResults({
  isSearchPage,
  searchApiUrl,
  mostSearchedTerms,
  inputRef,
  mobileOs,
  isNachKon = false,
  isNachKonDe = false,
  mostReadArticles,
  setOpenSearch,
  setCloseSearch,
  onHasResults,
  nachKonPortalUrl,
  isDE,
}: CdsSearchAndResultsProps) {
  const router = useRouter();
  const { q: query, p: page, searchType } = getSearchParameters(router);

  const latestSearchParameters = useRef({ q: '', p: 1, searchType: 'Unknown' });

  useEffect(() => {
    const { q: latestQuery, p: latestPage } = latestSearchParameters.current;
    if (query !== latestQuery || page !== latestPage) {
      if (query.length !== 0) {
        resetQuery.current?.(query);
        executeSearch(query, page, false, searchType);
        openSearch();
      } else {
        closeSearch();
      }
    }
  }, [query, page]);
  const [isOpen, setIsOpen] = useState(isSearchPage);
  const [suggestions, setSuggestions] = useState<SuggestionResultType[]>([]);
  const [latestSuggestion, setLatestSuggestion] = useState('');
  const [isSearchDown, setIsSearchDown] = useState(false);
  const [hits, setHits] = useState(0);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const { isPuk } = useIsPuk();

  const resetQuery = useRef<(query: string) => void>();
  const firstElement = useRef<HTMLDivElement>(null);

  const [queryResults, setQueryResults] = useState<
    SuggestionResultType[] | undefined
  >(undefined);
  const [typoCorrection, setTypoCorrection] = useState<string | undefined>();

  const { trackSearch } = useTracker(CdsSearch.name);

  const openSearch = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const closeSearch = useCallback(() => {
    if (nachKonPortalUrl.length > 1) {
      window.location.href = nachKonPortalUrl;
    } else if (isSearchPage) {
      router.back();
    } else {
      setQueryResults(undefined);
      setTypoCorrection(undefined);
      latestSearchParameters.current = { q: '', p: 1, searchType: 'Unknown' };
      onHasResults(false);
      setIsOpen(false);
      if (getUrlParameter(router, 'q') !== undefined) {
        router.back();
      }
    }
  }, [
    isSearchPage,
    setIsOpen,
    setQueryResults,
    onHasResults,
    nachKonPortalUrl,
    router,
  ]);

  const executeSearch = async (
    newQuery: string,
    newPage: number = 1,
    correctTypos: boolean = true,
    searchType: GA4SearchType,
  ): Promise<void> => {
    latestSearchParameters.current = { q: newQuery, p: newPage, searchType };

    const processedResults = await fetchResults(
      {
        query: newQuery,
        searchUrl: searchApiUrl,
        limit: RESULTS_PER_PAGE,
        offset: RESULTS_PER_PAGE * (newPage - 1),
        correctTypos,
      },
      mobileOs,
    );

    //@ts-ignore
    if (processedResults === 'SEARCH_DOWN') {
      setIsSearchDown(true);
      //@ts-ignore
      setResults('' as SuggestionResultType);
      latestSearchParameters.current = { q: '', p: 1, searchType: 'Unknown' };
    } else {
      updateSearchParameters(router, {
        q: newQuery,
        p: newPage,
        pushHistory: !isSearchPage && query === '',
        searchType,
      });

      setHits(processedResults.hits);

      const widgetsToRender = processedResults.results.length
        ? processedResults.results[0].widgetsToRender
        : [];

      const searchContext = createSearchEntity(
        newQuery,
        '',
        null,
        newPage ?? null,
        processedResults.results,
        processedResults.completions,
        widgetsToRender,
      );

      setIsSearchFocused(false);
      resetQuery.current?.(newQuery);

      trackSearch?.('search_execute', [searchContext]);

      setQueryResults(processedResults.results);

      isPuk &&
        GA4TrackSearchResultLoad(
          newQuery,
          searchType,
          newPage,
          processedResults.hits,
        );
      setTypoCorrection(processedResults.typoCorrection);
      onHasResults(processedResults.results !== undefined);
      const processedSuggestions = processedResults.completions;

      if (processedSuggestions[0]?.name !== undefined) {
        setLatestSuggestion(processedSuggestions[0].name);
      }
    }
  };

  const showPagination = hits !== undefined && hits !== 0;

  useEffect(() => {
    setOpenSearch(openSearch);
    setCloseSearch(closeSearch);
  }, [setOpenSearch, setCloseSearch, openSearch, closeSearch]);

  const hasResults = queryResults !== undefined;

  useEffect(() => {
    const a = firstElement.current?.querySelector('a');
    if ((!hasResults && !isSearchFocused) || (hasResults && isSearchFocused)) {
      inputRef.current?.focus();
    } else {
      a?.focus();
      a?.blur();
    }
  }, [inputRef, isSearchPage, hasResults, isSearchFocused, isOpen]);

  // Added this instead of an onBlur because it was messing up with the search field
  const searchContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(e.target as Node)
      ) {
        if (hasResults) {
          setIsSearchFocused(false);
          resetQuery.current?.(query);
        } else if (!isSearchPage) {
          closeSearch();
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeSearch, hasResults, isSearchFocused, isSearchPage, query]);

  return (
    <>
      {isOpen && (
        <CdsSearchContainer ref={searchContainerRef}>
          <CdsSearch
            initialQuery={query}
            searchUrl={searchApiUrl}
            autofocus={!isSearchPage}
            mostSearchedTerms={mostSearchedTerms}
            ref={inputRef}
            closeSearch={closeSearch}
            onSuggestionsChange={setSuggestions}
            executeSearch={(query, searchType) =>
              executeSearch(query, 1, false, searchType)
            }
            onFocusChange={setIsSearchFocused}
            setResetQuery={(f) => (resetQuery.current = f)}
            isDE={isDE}
          />
          <CdsSearchOverlay
            id="overlay"
            visible={!hasResults || isSearchFocused}
            opaque={!hasResults && isSearchPage}
            onClick={(e) => {
              if (e.currentTarget === e.target) {
                if (hasResults) {
                  setIsSearchFocused(false);
                  resetQuery.current?.(query);
                } else if (!isSearchPage) {
                  closeSearch();
                }
              }
            }}
          />
        </CdsSearchContainer>
      )}
      {queryResults !== undefined && (
        <ResultsSection>
          <Main>
            <CdsSearchResultsWrapper
              totalHits={hits}
              searchResultsTitle={query}
              searchResults={queryResults ?? []}
              typoCorrection={typoCorrection}
              mostSearchedTerms={mostSearchedTerms}
              mostReadArticles={mostReadArticles}
              isNachKon={isNachKon}
              isNachKonDe={isNachKonDe}
              nachKonPortalUrl={nachKonPortalUrl}
              page={page}
              latestSuggestion={latestSuggestion}
              isSearchDown={isSearchDown}
              executeSearch={(
                query: string,
                page: number = 1,
                correctTypos: boolean = true,
                searchType: GA4SearchType,
              ) => executeSearch(query, page, correctTypos, searchType)}
              firstItemRef={firstElement}
            />
            <HStack justifyContent={'center'} alignItems={'center'}>
              {showPagination && (
                <Pagination
                  activePage={page}
                  numPages={Math.ceil(hits / 10)}
                  onPageClick={(p, e) => {
                    executeSearch(query, p, false, searchType);
                    window.scrollTo(0, 0);
                  }}
                />
              )}
            </HStack>
          </Main>
        </ResultsSection>
      )}
    </>
  );
}

const ResultsSection = styled(Section, {
  marginBottom: '0 !important',
  gap: 0,
});

const Main = styled('main', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$subsection',
});
