import { NextRouter } from 'next/router';
import { isInternalLink, sanitizeInternalLink } from './helpers/linkHelpers';

export function navigate(href: string, router: NextRouter): void {
  if (isInternalLink(href)) {
    // note: just like NextLink, router takes care of adding the subpaths
    return void router.push(sanitizeInternalLink(href));
  }
  if (typeof window !== 'undefined') {
    return window.open(href, '_self') as unknown as void;
  }
  return;
}
