import { PropsWithChildren, useCallback, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import {
  globalStyles,
  interaction___search,
} from '@neui/styleguide-commerzbank';
import { styled } from '@neui/core';
import { Feature, useFeature } from 'flagged';

import { Metadata, isGpp, isGppDE } from '@utils/DataFetcher';
import { createPortalEntity, useTracker } from '@utils/snowplowTracking';
import { SeoData } from '@components/SEO/SeoData';
import { MobileChecker } from '@utils/MobileChecker';
import { Header } from '@components/neui-components/atoms/Header';
import { Footer } from '@components/neui-components/atoms/Footer';
import { Section } from '@components/neui-components/atoms/Section';
import { baseTheme } from 'styling/stitches.config';
import { MostReadArticleType } from 'pages';
import { Chat } from '@components/Chat/Chat';
import {
  useChatStore,
  selectChatProps,
  selectChatActions,
} from '@components/Chat/store/store';
import { dynamicHref, useMakeLink } from '@utils/helpers/linkHelpers';
import { $t, currentLanguage, currentDomain, basepath } from '@utils/i18n';
import { apiClient } from '@components/Chat/ChatWindow';
import { EnvironmentProps } from '@utils/env';
import { CdsSearchAndResults } from '@components/Search/CdsSearchAndResults';
import { GA4TrackNavigationClick, GA4TrackPageView } from '@utils/tracking';
import { IsPukProvider } from '@utils/isPukBoolean';
import { maskIfNecessary } from '@components/Search/helpers';

import { NavLink, NavItemProps } from '../NavItems/CdsNavItems';

type LayoutProps = {
  category: string;
  title: string;
  slug: string;
  query?: string;
  isIndexPage?: boolean;
  isSearchPage: boolean;
  metadata: Metadata;
  mostSearchedTerms?: string[];
  mostReadArticles?: MostReadArticleType[];
  excerpt?: string;
  features: EnvironmentProps['features'];
  isOBPage?: boolean;
};

export default function CdsLayout({
  children,
  isIndexPage = false,
  title,
  slug,
  isSearchPage,
  metadata,
  category,
  mostSearchedTerms,
  mostReadArticles,
  excerpt,
  features,
  isOBPage,
}: PropsWithChildren<LayoutProps>) {
  globalStyles();
  globalStyles();

  const portalContext = createPortalEntity(category ?? $t('HOME'), title);

  const { searchUrl, constants, deploymentStage } = metadata;
  const [itm, setItm] = useState<string | undefined>();

  const makeLink = useMakeLink(deploymentStage);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const itmParam = params.get('itm') ?? undefined;
    setItm(itmParam);
  }, []);

  const portals = [
    {
      regex: /nachhaltigkeit/,
      url: 'https://www.commerzbank.de/nachhaltigkeit',
    },
    {
      regex: /sustainability/,
      url: 'https://www.commerzbank.de/sustainability',
    },
    { regex: /konzern/, url: 'https://www.commerzbank.de/konzern/' },
    { regex: /group/, url: 'https://www.commerzbank.de/group/' },
  ];

  //nachKon is from the german names nachhaltigkeitsportal and konzern, the two pages in english and german we want to join, its shorter than their english version
  let nachKonSearchUrl = process.env.NEXT_PUBLIC_NACHKON_SEARCH_URL_DE ?? ''; //DE default

  const isNachKon: boolean =
    itm !== undefined && portals.some((portal) => portal.regex.test(itm));

  const isNachKonDE: boolean =
    itm !== undefined &&
    (portals[0].regex.test(itm) || portals[2].regex.test(itm));

  const searchApiUrl = !isNachKon
    ? searchUrl
    : isNachKonDE
      ? nachKonSearchUrl
      : process.env.NEXT_PUBLIC_NACHKON_SEARCH_URL_EN ?? '';

  const nachKonPortalUrl =
    itm !== undefined ? portals.find((p) => p.regex.test(itm))?.url : undefined;

  const nachKonMostSearchedTerms = isNachKon
    ? isNachKonDE
      ? [
          'unternehmensführung',
          'nachhaltigkeitsstrategie',
          'diversity',
          'karriere',
          'kurse',
          'edelmetalle',
          'pressemitteilung',
        ]
      : [
          'governance',
          'sustainability strategy',
          'diversity',
          'career',
          'courses',
          'precious metals',
          'press release',
        ]
    : undefined;

  const isPuk = !isGpp && !isNachKon;

  const is404 = slug.includes('404');
  const { isChatOpen, userState } = useChatStore(selectChatProps);
  const { setUser, setChatState } = useChatStore(selectChatActions);

  const {
    trackPageView,
    enableLinkClickTracking,
    trackButtonClick,
    trackLinkClick,
  } = useTracker(CdsLayout.name);

  const openSearch = useRef(() => {});
  const closeSearch = useRef(() => {});

  const [hasSearchResults, setHasSearchResults] = useState(false);

  const seoTitle = isIndexPage
    ? is404
      ? title
      : 'Commerzbank Kundenservice'
    : title + ' - ' + $t('SEO_TITLE_APPENDIX');

  useEffect(() => {
    // SPAs have a problem of not resetting the focus on page change so
    // we gotta do this manually
    const header = document.getElementsByTagName('header');
    !isOBPage && header[0].focus();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [slug]);

  const enableChat = useFeature('enableChat');

  useEffect(() => {
    async function initiateChatBot() {
      if (!enableChat) {
        console.log('chat is disabled');
        return;
      }

      try {
        const res = await apiClient.chat.initiateChatUsingPost(
          {
            PlaceWhereCallWasInitiated: '91101',
            Language: 'L01', // Deutsch
            'CDS-AppVersion': '0.0.1',
          },
          {
            headers: {
              'Content-Type': 'application/json',
              pToken: window?.ccb_cif?.pToken ?? '',
            },
          },
        );

        const data = res.data.result?.data;
        setChatState({
          workgroupName: data?.mobileAppAvailability?.workgroupName,
        });
      } catch (e) {
        console.log('Unable to initiate chat');
      }
    }

    initiateChatBot();
  }, []);

  useEffect(() => {
    window.addEventListener('offline', () => {
      setUser({ status: 'offline' });
      // this is weird but works
      window.addEventListener('online', () => {
        setUser({ status: 'reconnected' });
      });
    });
  }, []);

  const searchIconProps = {
    label: $t('SEARCH'),
    icon: interaction___search,
    iconAriaHidden: true,
    onClick: () => {
      isPuk &&
        GA4TrackNavigationClick(
          'mainNavigationClick',
          'quick_access',
          'Search',
        );
      trackButtonClick?.('header search icon', 'navigation', [portalContext]);
      openSearch.current();
    },
    ['data-cy']: 'search-icon',
    shouldShow: true,
  };

  const obUrl = makeLink('kunden', '/banking/landingpage');

  const onlineBankingIconProps = {
    label: $t('ONLINE_BANKING'),
    icon: <OnlineBankingIcon />,
    href: obUrl,
    iconAriaHidden: true,
    onClick: () => {
      isPuk &&
        GA4TrackNavigationClick(
          'mainNavigationClick',
          'quick_access',
          $t('ONLINE_BANKING'),
        );
      trackLinkClick?.(obUrl, $t('ONLINE_BANKING'));
    },
    ['data-cy']: 'search-icon',
    shouldShow: true,
  };

  //   const languageLinkProps = {
  //     label: foreignLanguage.toUpperCase(),
  //     onClick: onLanguageLinkClick,
  //     shouldShow: isGpp,
  //   };

  const isSearchVisible = isIndexPage || isSearchPage;

  const metaRobotsTag = isSearchPage ? 'noindex, nofollow' : 'index, follow';

  const logoSrc = `${currentDomain}/commerzbank-logo.png`;

  const mobileChecker = new MobileChecker();
  const mobileOs = mobileChecker.deviceType;

  const navLinks: NavItemProps[] = [searchIconProps, onlineBankingIconProps];

  const inputRef = useRef<HTMLInputElement>(null);

  const absoluteUrl = `${currentDomain}${basepath}${slug}`;

  const setOpenSearch = useCallback(
    (f: () => void) => (openSearch.current = f),
    [],
  );

  const setCloseSearch = useCallback(
    (f: () => void) => (closeSearch.current = f),
    [],
  );

  useEffect(() => {
    enableLinkClickTracking?.({
      pseudoClicks: true,
      options: {
        denylist: isIndexPage
          ? ['c-bjBqHr-daxzDk-look-secondary', 'c-cIdiJW']
          : [],
      },
      context: [portalContext],
    });

    // hack: since snowplow tracking sometimes seems to pick up the old title, postpone tracking the page view into the next animation frame
    window.requestAnimationFrame(() => {
      trackPageView?.({ context: [portalContext] });

      // NOTE(benjamin): trackPageView is set up async so GA4TrackPageView fires twice
      // on initial load. This should be cleaned up (encapsulate tracking).
      if (trackPageView !== undefined) {
        const pageUrl = maskIfNecessary(window.location.href);
        isPuk && GA4TrackPageView(pageUrl, seoTitle);
      }
    });
  }, [trackPageView, enableLinkClickTracking, category, title, isIndexPage]);

  const [isFixed, setIsFixed] = useState(false);
  const layoutRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleScroll = () => {
      if (layoutRef.current) {
        const rect = layoutRef.current.getBoundingClientRect();
        if (rect.top <= 0 && rect.bottom >= 0) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <IsPukProvider value={isPuk}>
      <MinHeightLayout
        className={baseTheme}
        ref={layoutRef}
        //This is what makes the sticky search results work
        css={{ transform: isFixed ? undefined : 'scale(1)' }}
      >
        <SeoData
          title={seoTitle}
          description={excerpt ?? undefined}
          absoluteUrl={absoluteUrl}
          slug={slug}
          metaRobotsTag={metaRobotsTag}
          documentLanguage={currentLanguage}
          isSearchVisible={isSearchVisible}
          isIndexPage={isIndexPage || isSearchPage}
          imageSrc={dynamicHref(logoSrc)}
        />
        {!isOBPage && (
          <Header
            isSearchActive={false}
            title={$t('HEADER_TITLE')}
            navItems={
              <nav>
                <NavWrapper>
                  {navLinks.map((navLink, idx) => (
                    <StyledList
                      key={idx}
                      style={{ listStyle: 'none', display: 'flex' }}
                    >
                      <NavLink
                        isSvg={idx === navLinks.length - 1}
                        {...navLink}
                      />
                    </StyledList>
                  ))}
                </NavWrapper>
              </nav>
            }
          />
        )}
        <CdsSearchAndResults
          isSearchPage={isSearchPage}
          searchApiUrl={searchApiUrl}
          mostSearchedTerms={nachKonMostSearchedTerms ?? mostSearchedTerms}
          inputRef={inputRef}
          mobileOs={mobileOs}
          isNachKon={isNachKon}
          isNachKonDe={isNachKonDE}
          mostReadArticles={mostReadArticles ?? []}
          setOpenSearch={setOpenSearch}
          setCloseSearch={setCloseSearch}
          onHasResults={setHasSearchResults}
          nachKonPortalUrl={nachKonPortalUrl ?? ''}
          isDE={isPuk || isGppDE || isNachKonDE}
        />
        <GrowingMain>
          {!hasSearchResults && (
            <>
              {children}
              <StyledSection />
            </>
          )}
        </GrowingMain>
        <Footer constants={constants} features={features} />
        <Feature name="enableChat">{isChatOpen && <Chat />}</Feature>
      </MinHeightLayout>
    </IsPukProvider>
  );
}

export const OnlineBankingIcon = () => {
  return (
    <IconContainer>
      <img src={dynamicHref('/icons/laptop-logo.svg')} alt="" />
      <img src={dynamicHref('/icons/smartphone-logo.svg')} alt="" />
    </IconContainer>
  );
};

const IconContainer = styled('div', {
  ':nth-child(1)': {
    display: 'none',
  },
  ':nth-child(2)': {
    display: 'block',
  },
  '@md': {
    ':nth-child(1)': {
      display: 'block',
    },
    ':nth-child(2)': {
      display: 'none',
    },
  },
});

const MinHeightLayout = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  overflow: 'hidden',
});

const GrowingMain = styled('main', {
  flexGrow: 1,
  overflow: 'hidden',
});

const StyledSection = styled(Section, {
  paddingTop: '0 !important',
});

const ResultsSection = styled(Section, {
  marginBottom: '0 !important',
  gap: 0,
});

const NavWrapper = styled('ul', {
  display: 'flex',
  flexDirection: 'row',
  gap: 24,
  '@md': {
    gap: 32,
  },
});

const StyledList = styled('li', {
  display: 'flex', // this removes the weird padding
  listStyle: 'none',
});
