import React, { createContext, useContext, ReactNode } from 'react';

interface IsPukContextType {
  isPuk: boolean;
}

const isPukContext = createContext<IsPukContextType | undefined>(undefined);

interface IsPukProviderProps {
  value: boolean;
  children: ReactNode;
}

export const IsPukProvider: React.FC<IsPukProviderProps> = ({
  value,
  children,
}) => {
  return (
    <isPukContext.Provider value={{ isPuk: value }}>
      {children}
    </isPukContext.Provider>
  );
};

export const useIsPuk = (): IsPukContextType => {
  const context = useContext(isPukContext);
  if (context === undefined) {
    throw new Error('useIsPuk must be used within a IsPukProvider');
  }
  return context;
};
