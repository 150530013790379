import { styled } from '@neui/core';
import { VStack, HStack, AspectRatio } from '@neui/layout';
import { interaction___close, Text } from '@neui/styleguide-commerzbank';
import debounce from 'lodash/debounce';
import { useEffect, useRef } from 'react';

import { IconLink } from '@components/neui-components/atoms/IconLink';

import { useAutosizeTextArea } from './hooks/useAutosizeTextArea';
import { apiClient } from './ChatWindow';
import {
  ChatMessageType,
  selectChatActions,
  selectChatProps,
  useChatStore,
} from './store/store';
import { createUserMessage } from './utils/utils';
import { SendIconSVG } from './send-icon';

type ChatFooterProps = {
  userInput: string;
  setUserInput: (i: string) => void;
  onSubmit: (userMsg: ChatMessageType) => Promise<void>;
};

export function ChatFooter({
  userInput,
  setUserInput,
  onSubmit,
}: ChatFooterProps) {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(inputRef.current, userInput);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const debouncedTypingStateUsingPost = useRef(
    debounce(
      async (args, params) =>
        await apiClient.chat.typingStateUsingPost(args, params),
      400,
    ),
  ).current;

  useEffect(() => {
    return () => {
      debouncedTypingStateUsingPost.cancel();
    };
  }, [debouncedTypingStateUsingPost]);

  const { userState, msgs, agentState, temporaryMsgs } =
    useChatStore(selectChatProps);
  const { setTemporaryMsgs, setQuickReplies } = useChatStore(selectChatActions);

  return (
    <Wrapper>
      <VStack spacing={8}>
        <HStack spacing={8} alignItems={'center'}>
          <InputContainer>
            <StyledInput
              // onBlur={() => {
              //   inputRef?.current?.setSelectionRange(0, 0);
              //   inputRef?.current?.focus();
              // }}
              ref={inputRef}
              onChange={async (e) => {
                setUserInput(e.target.value);
                await debouncedTypingStateUsingPost(
                  {
                    participantId: userState.participantId,
                    typingIndicator: true,
                  },
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      pToken: window?.ccb_cif?.pToken ?? '',
                    },
                  },
                );
              }}
              placeholder="Geben Sie Ihr Anliegen ein"
              value={userInput}
              onKeyDown={async (e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  if (
                    userState.state === 4 ||
                    !userInput.length ||
                    userInput.length > 256
                  ) {
                    return;
                  }

                  await debouncedTypingStateUsingPost(
                    {
                      participantId: userState.participantId,
                      typingIndicator: false,
                    },
                    {
                      headers: {
                        'Content-Type': 'application/json',
                        pToken: window?.ccb_cif?.pToken ?? '',
                      },
                    },
                  );
                  const displayName =
                    agentState.agents[userState.participantId];
                  const newUserMsg = createUserMessage({
                    userInput,
                    participantId: userState.participantId,
                    displayName,
                    msgsLength: msgs.length,
                  });
                  setUserInput('');
                  setQuickReplies([]);
                  setTemporaryMsgs([...temporaryMsgs, newUserMsg]);
                  await onSubmit(newUserMsg);
                }
              }}
              maxLength={256}
              disabled={userState.state === 4}
            ></StyledInput>
            <StyledXIcon
              icon={interaction___close}
              animationDirection="none"
              onClick={() => {
                setUserInput('');
              }}
            ></StyledXIcon>
          </InputContainer>
          <StyledAspectRatio
            aspectRatio={'1'}
            size={22}
            renderAs={'button'}
            aria-disabled={userState.state === 4}
            css={{
              border: 'none',
              background: 'none',
              cursor: userState.state === 4 ? 'unset' : 'pointer',
            }}
            onClick={async () => {
              if (
                userState.state === 4 ||
                !userInput.length ||
                userInput.length > 256
              ) {
                return;
              }
              const displayName = agentState.agents[userState.participantId];
              const newUserMsg = createUserMessage({
                userInput,
                participantId: userState.participantId,
                displayName,
                msgsLength: msgs.length,
              });
              setUserInput('');
              setQuickReplies([]);
              setTemporaryMsgs([...temporaryMsgs, newUserMsg]);
              // setMsgs([...msgs, newUserMsg]);
              await onSubmit(newUserMsg);
            }}
          >
            <SendIconSVG fill={userState.state === 4 ? '#ABBAC0' : '#002E3C'} />
          </StyledAspectRatio>
        </HStack>
        <Text type="helper">{`${userInput.length}/256`}</Text>
      </VStack>
    </Wrapper>
  );
}
const Wrapper = styled(VStack, {
  background: '$colors$neutral0',
  position: 'relative',
  width: '100%',
  height: 'auto',
  padding: 16,
  bottom: 0,
  zIndex: 10000000,
  // flex: 'none',
  flex: '0 0 100px',
  order: 3,
});

const StyledAspectRatio = styled(AspectRatio, {
  '&:focus-visible': {
    outline: '2px solid $text-standard',
    outlineOffset: 4,
    borderRadius: 8,
  },
});

const InputContainer = styled(HStack, {
  width: '100%',
  position: 'relative',
  alignItems: 'center',
});

const StyledInput = styled('textarea', {
  height: 56,
  maxHeight: 'max-content',
  position: 'relative',
  width: '100%',
  padding: '16px 48px 16px 16px',
  border: 'none',
  boxShadow: '0 0 0 1px #506C74',
  borderRadius: 8,
  color: '$text-standard',
  '::placeholder': {
    color: '$text-disabled',
  },
  fontFamily: '$sans',
  fontSize: '$mobile-5',
  resize: 'none',
  maxLines: 1,
  lineHeight: '$mobile-5',
  letterSpacing: '$mobile-5',
  transition: 'box-shadow 300ms ease-in-out',
  '&:hover': {
    boxShadow: '0 0 0 2px #2D5562',
  },
  '&:focus-visible': {
    outline: '3px solid $text-standard',
  },
});

const StyledXIcon = styled(IconLink, {
  position: 'absolute',
  right: 16,
  bottom: 16,
});
