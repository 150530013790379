import { VariantProps, keyframes, styled } from '@neui/core';
import { Box, HStack, VStack } from '@neui/layout';
import {
  Text,
  interaction___refresh,
  Spinner,
} from '@neui/styleguide-commerzbank';
import { PropsWithChildren, useEffect, useState } from 'react';

import { IconLink } from '@components/neui-components/atoms/IconLink';

import {
  selectChatActions,
  selectChatProps,
  useChatStore,
} from './store/store';
import { apiClient } from './ChatWindow';
import { formatMsgTime } from './utils/utils';

export type ChatMessageProps = VariantProps<typeof Wrapper> & {
  // message: string; // we will probably need more than that in the future
  shouldHideAuthor?: boolean;
  isTryingToResend?: boolean;
  timestamp: string;
  sequenceNumber: number;
  participantId: string;
  displayName: string;
  msgValue?: string;
};

const Container = styled(Box, {
  padding: '12px 16px',
  borderRadius: 8,
  width: 'fit-content',
  hyphens: 'auto',
});

export const ChatAuthor = styled(Text, {
  marginBottom: 12,
});

const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const Wrapper = styled(VStack, {
  animation: `${fadeIn} 150ms cubic-bezier(0.42, 0, 0.58, 1)`,
  variants: {
    isUserBubble: {
      false: {
        paddingRight: 48,
        alignItems: 'flex-start',
        [`${Container}`]: {
          background: '$colors$petrol100',
        },
      },
      true: {
        paddingLeft: 48,
        alignItems: 'flex-end',
        [`${Container}`]: {
          background: '$colors$neutral0',
          border: '1px solid $colors$petrol300',
        },
        [`${ChatAuthor}`]: {
          textAlign: 'right',
        },
      },
    },
  },
});

export function ChatMessage({
  shouldHideAuthor = true,
  displayName,
  timestamp,
  participantId,
  msgValue,
  children,
}: PropsWithChildren<ChatMessageProps>) {
  const isByChatbot = displayName === 'Bot#Commerzbank ChatBot';

  const { msgs, userState, unsentMsgs } = useChatStore(selectChatProps);
  const isByUser = !isByChatbot && participantId === userState.participantId;

  const [isTryingToResend, setIsTryingToResend] = useState(false);
  const [hasBeenSent, setHasBeenSent] = useState<boolean>(true);

  useEffect(() => {
    if (
      isByUser &&
      unsentMsgs.length > 0 &&
      unsentMsgs.find((x) => x.value === msgValue && x.timestamp === timestamp)
    ) {
      setHasBeenSent(false);
    }
  }, [unsentMsgs, isByUser, msgValue, timestamp]);

  const { setUnsentMsgs } = useChatStore(selectChatActions);

  const time = formatMsgTime(timestamp);
  const signature = isByUser
    ? 'Sie'
    : isByChatbot
      ? 'Chatbot'
      : displayName ?? 'Chatbot';

  async function resendMsg(msgValue: string) {
    try {
      setIsTryingToResend(true);
      const res = await apiClient.chat.sendMessageUsingPost(
        {
          participantId: userState.participantId,
          message: msgValue,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            pToken: window?.ccb_cif?.pToken ?? '',
          },
        },
      );

      if (res.status !== 200) {
        throw new Error(`Couldnt send message, status ${res.status}`);
      } else {
        const updatedUnsentMsgs = unsentMsgs.filter(
          (msg) => msg.value !== msgValue && msg.timestamp !== timestamp,
        );

        setUnsentMsgs(updatedUnsentMsgs);
        setHasBeenSent(true);
      }
    } catch (e) {
      setIsTryingToResend(false);
    } finally {
      setIsTryingToResend(false);
    }
  }

  return (
    <>
      <Wrapper isUserBubble={isByUser} spacing={4}>
        <HStack spacing={8} alignItems={'center'}>
          <Container>{children}</Container>
          {!hasBeenSent && (
            <>
              {isTryingToResend ? (
                <Spinner label="" variant="indeterminate" size={24} />
              ) : (
                <IconLink
                  icon={interaction___refresh}
                  animationDirection="none"
                  onClick={async () => await resendMsg(msgValue ?? '')}
                />
              )}
            </>
          )}
        </HStack>
        {!shouldHideAuthor && hasBeenSent && (
          <ChatAuthor type={'helper'}>{`${signature} - ${time}`}</ChatAuthor>
        )}
      </Wrapper>
    </>
  );
}
