import { AsProps, CSSProps, styled } from '@neui/core';
import { Flex, HStack } from '@neui/layout';
import { Typography } from '@neui/styleguide-commerzbank';
import { forwardRef, AnchorHTMLAttributes } from 'react';

import { Icon, IconProps } from './Icon';

export type IconLinkProps = AsProps &
  CSSProps &
  AnchorHTMLAttributes<HTMLAnchorElement> &
  Pick<IconProps, 'icon' | 'variant'> & {
    iconPosition?: 'left' | 'right';
    disabled?: boolean;
    animationDirection?: 'left' | 'right' | 'top' | 'bottom' | 'none';
    to?: string;
    tabIndex?: number;
    iconAriaHidden?: boolean;
    iconTitle?: string;
  };

export const Wrapper = styled(Flex, {
  $$name: 'IconLink.Wrapper',
  display: 'flex',
  alignItems: 'center',
  transition: 'transform 200ms ease',

  variants: {
    iconPosition: {
      left: {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
      },
      right: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
      },
    },
  },
});
const StyledTypography = styled(Typography, {});

const StyledIcon = styled(Icon, {});

const StyledLink = styled('a', {
  all: 'unset',

  [`& ${Wrapper}`]: {
    transform: 'translate(0px, 0px)',
  },
  '&:not([disabled])': {
    cursor: 'pointer',
  },
  '&:focus-visible': {
    [`& ${Wrapper}`]: {
      outline: '2px solid $text-standard',
      border: 'none',
      borderRadius: 8,
      outlineOffset: 4,
    },
  },
  '&[disabled]': {
    pointerEvents: 'none',
    [`& ${StyledTypography}`]: {
      color: '$text-disabled',
    },
    [`& ${StyledIcon} svg`]: {
      fill: '$text-disabled',
    },
  },
  variants: {
    animationDirection: {
      top: {
        '&:hover, &:focus-visible': {
          [`& ${Wrapper}`]: {
            transform: 'translateY(-5px)',
          },
        },
      },
      bottom: {
        '&:hover, &:focus-visible': {
          [`& ${Wrapper}`]: {
            transform: 'translateY(5px)',
          },
        },
      },
      left: {
        '&:hover, &:focus-visible': {
          [`& ${Wrapper}`]: {
            transform: 'translateX(-5px)',
          },
        },
      },
      right: {
        '&:hover, &:focus-visible': {
          [`& ${Wrapper}`]: {
            transform: 'translateX(5px)',
          },
        },
      },
      none: {},
    },
  },
});

export const IconLink = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  IconLinkProps
>(
  (
    {
      iconPosition = 'right',
      animationDirection = 'right',
      icon,
      variant,
      children,
      href,
      renderAs = href ? 'a' : 'button',
      tabIndex,
      iconAriaHidden,
      iconTitle,
      ...rest
    },
    forwardedRef,
  ) => {
    return (
      <StyledLink
        ref={forwardedRef}
        href={href}
        animationDirection={animationDirection}
        as={renderAs}
        tabIndex={tabIndex}
        {...rest}
      >
        <Wrapper
          iconPosition={iconPosition}
          css={{
            gap: children ? '$component-2' : 0,
          }}
        >
          <StyledTypography weight="medium" size={6}>
            {children}
          </StyledTypography>
          <StyledIcon
            aria-hidden={iconAriaHidden}
            title={iconTitle}
            size="small"
            icon={icon}
            variant={variant}
          />
        </Wrapper>
      </StyledLink>
    );
  },
);
IconLink.displayName = 'IconLink';
