import { CSSProps, styled, VariantProps } from '@neui/core';
import { Box } from '@neui/layout';
import { forwardRef, HTMLAttributes, ReactNode } from 'react';

const Wrapper = styled(Box, {});

const ItemsWrapper = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  gap: '$space$component-2',
  height: '$space$component-6',
});

export type BreadcrumbsProps = HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof Wrapper> &
  CSSProps & {
    label: string;
    children: ReactNode;
  };

export const Breadcrumbs = forwardRef<HTMLDivElement, BreadcrumbsProps>(
  ({ label, children, ...rest }, forwardedRef) => {
    return (
      <Wrapper ref={forwardedRef} {...rest} as="nav" aria-label={label}>
        <ItemsWrapper as="ul">{children}</ItemsWrapper>
      </Wrapper>
    );
  },
);

Breadcrumbs.displayName = 'Breadcrumbs';
