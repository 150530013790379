import { AsProps, css, CSSProps, styled, VariantProps } from '@neui/core';
import { Flex } from '@neui/layout';
import { Typography } from '@neui/styleguide-commerzbank';
import { forwardRef, HTMLAttributes } from 'react';

import { BannerIconBadge } from '../atoms/BannerIconBadge';
import { IconProps, Icon } from '../atoms/Icon';
import { BannerBadgeLook } from '../atoms/BannerTextBadge';

export type ActionButtonProps = HTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonStyles> &
  CSSProps &
  AsProps &
  Pick<IconProps, 'variant' | 'icon'> & {
    href?: string;
    onClick?: (e?: MouseEvent) => void;
    label: string;
    badgeIcon?: IconProps['icon'];
    badgeIconLook?: Extract<
      BannerBadgeLook,
      'primary' | 'supplementary' | 'highlight' | 'optional'
    >;
  };

const ThumbnailWrapper = styled(Flex, {
  borderRadius: '50%',
  display: 'inline-flex',
  aspectRatio: '1 / 1',
  transition: 'all 300ms ease-in-out',
  overflow: 'hidden',
  '&:not(:disabled)': {
    cursor: 'pointer',
  },
});

const buttonStyles = css({
  position: 'relative',
  textDecoration: 'none',
  display: 'flex',
  width: 112,
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 8,
  background: 'none',
  color: 'inherit',
  border: 'none',
  padding: 0,
  font: 'inherit',
  outline: 'inherit',
  overflowWrap: 'break-word',
  hyphens: 'auto',
  '@lg': {
    width: 128,
  },
  '&:focus-visible': {
    outline: '2px solid $text-standard',
    outlineOffset: 4,
    border: 'none',
    borderRadius: 8,
  },
  variants: {
    look: {
      primary: {
        [`& ${ThumbnailWrapper}`]: {
          margin: 6,
          backgroundColor: '$colors$primary',
          boxShadow:
            '0 0 0 6px $colors$primary, 0 0 0 0.5px $colors$primary, 0 0 0 2px $colors$primary, 0 0 0 1px $colors$primary',
        },
        '&:hover': {
          [`& ${ThumbnailWrapper}`]: {
            boxShadow:
              '0 0 0 6px $colors$primary-hover, 0 0 0 0.5px $colors$primary-hover, 0 0 0 2px $colors$primary-hover, 0 0 0 1px $colors$primary-hover',
            backgroundColor: '$colors$primary-hover',
          },
        },
        '&:active': {
          [`& ${ThumbnailWrapper}`]: {
            boxShadow:
              '0 0 0 0 $colors$primary-hover, 0 0 0 0 $colors$primary-hover, 0 0 0 0 $colors$primary-hover, 0 0 0 0 $colors$primary-hover',
          },
        },
      },
      secondary: {
        [`& ${ThumbnailWrapper}`]: {
          size: 60,
          border: '1px solid $colors$secondary',
          '@md': {
            size: 72,
          },
        },
        '&:hover': {
          [`& ${ThumbnailWrapper}`]: {
            border: '1px solid $colors$secondary-hover',
            boxShadow: '0px 0px 0px 1px inset $colors$secondary-hover',
          },
        },
        '&:active': {
          [`& ${ThumbnailWrapper}`]: {
            border: '1px solid $colors$secondary-hover',
            boxShadow: 'inset 0px 0px 0px 3px $colors$secondary-hover',
          },
        },
      },
    },
    disabled: {
      true: {
        cursor: 'default !important',
        pointerEvents: 'none',
        border: '1px solid $colors$disabled',
        [`& *`]: {
          color: '$colors$disabled !important',
          fill: '$colors$disabled !important',
        },
        [`& ~ *`]: {
          color: '$colors$disabled !important',
        },
      },
    },
  },
});

const StyledIcon = styled(Icon, {
  margin: 12,
  '@md': {
    margin: 18,
  },
});

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  (
    {
      href,
      disabled,
      renderAs: Component = !disabled && href ? 'a' : 'button',
      label,
      icon,
      variant = 'outline',
      look = 'primary',
      badgeIcon,
      badgeIconLook,
      ...rest
    },
    forwardedRef,
  ) => {
    const StyledComponent = styled(Component, buttonStyles);
    return (
      <StyledComponent
        {...rest}
        ref={forwardedRef}
        href={href}
        disabled={disabled}
        look={look}
      >
        {badgeIcon && (
          <StyledBannerIconBadge
            icon={badgeIcon}
            size="small"
            look={badgeIconLook}
          />
        )}
        <ThumbnailWrapper alignItems="center" justifyContent="center">
          <StyledIcon
            aria-hidden="true"
            icon={icon}
            variant={variant}
            size="small"
          />
        </ThumbnailWrapper>
        <Typography textAlign={'center'} size={7} weight={'medium'}>
          {label}
        </Typography>
      </StyledComponent>
    );
  },
);

ActionButton.displayName = 'ActionButton';

const StyledBannerIconBadge = styled(BannerIconBadge, {
  position: 'absolute',
  width: '28px !important',
  top: 0,
  transform: 'translateX(75%)',
});
