import { keyframes, styled } from '@neui/core';
import { VStack } from '@neui/layout';

import { Api } from '../../chatbot-api';
import { ChatHeader } from './ChatHeader';
import { ScreenStateRenderer } from './ScreenStateRenderer';

export const apiClient = new Api({
  baseUrl: process.env.NEXT_PUBLIC_OB_HOST ?? 'https://kunden.commerzbank.de',
});

type ChatWindowProps = {
  isChatMinimized: boolean;
};

export function ChatWindow({ isChatMinimized }: ChatWindowProps) {
  return (
    <Wrapper state={isChatMinimized ? 'closing' : 'opening'}>
      <ChatHeader />
      <ScreenStateRenderer />
    </Wrapper>
  );
}

const fadeIn = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale(0.6)',
    transformOrigin: 'bottom right',
  },
  '100%': {
    opacity: 1,
    transform: 'scale(1)',
    transformOrigin: 'bottom right',
  },
});

const fadeOut = keyframes({
  '0%': { opacity: 1, transform: 'scale(1)' },
  '99%': { opacity: 0, transform: 'scale(0.6)' },
  '100%': { display: 'none !important' },
});

const Wrapper = styled(VStack, {
  display: 'none',
  zIndex: 1338,
  width: '100%',
  height: '100%',
  position: 'fixed',
  right: 0,
  bottom: 0,
  backgroundColor: '$colors$neutral0',
  boxShadow: '0 6px 36px -8px rgb(0 0 0 / 36%)',
  overflow: 'hidden',
  '@sm': {
    borderRadius: 16,
    minHeight: 400,
    width: 400,
    right: 16,
    bottom: 32,
    height: 628,
    maxHeight: 628,
  },
  variants: {
    state: {
      opening: {
        display: 'flex',
        animation: `${fadeIn} 240ms cubic-bezier(0.34, 0.63, 0, 1)`,
      },
      closing: {
        animation: `${fadeOut} 240ms cubic-bezier(0.34, 0.63, 0, 1)`,
      },
    },
  },
});
