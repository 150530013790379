import { useEffect, useState } from 'react';

import { Metadata, DeploymentStage } from '@utils/DataFetcher';

export const umlauts = 'äöüß';
export const umlautsReplacements = ['ae', 'oe', 'ue', 'ss'];
export const uml = new RegExp(umlauts.split('').join('|'), 'g');

export function slugify(str: string, lang?: string) {
  const language = lang ?? 'de';

  const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœøṕŕśșțùúüûǘẃẍÿź·/_,:;';
  const b = 'aaaaaaaaceeeeghiiiimnnnooooooprsstuuuuuwxyz------';
  const p = new RegExp(a.split('').join('|'), 'g');
  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(uml, (c) => {
      return umlautsReplacements[umlauts.indexOf(c)];
    }) // replace umlauts
    .replace(p, (c) => {
      return b.charAt(a.indexOf(c));
    }) // Replace special characters
    .replace(/&/g, language === 'de' ? '-und-' : '-and-') // Replace &
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

function isExceptionalLink(href: string) {
  const url = new URL(href, `https://${currentHost}`);
  // a telephone or smth else
  if (url.protocol !== 'https:' && url.protocol !== 'http:') return true;
  // a resource
  const rgx = new RegExp(
    '.(jpg|jpeg|png|gif|pdf|css|js|mp3|mp4|zip|rar|txt|webp|svg)$',
  );
  if (href.match(rgx)) return true;

  return false;
}

export function slashify(url: string | undefined) {
  if (!url) {
    return '';
  }
  // dont add trailing slash to exceptions
  if (isExceptionalLink(url)) {
    return url;
  }
  return url.endsWith('/') ? url : url + '/';
}

export const hosts: Record<Metadata['basename'], string> = {
  /** NOTE(filipe): the cds-wissen host is NOT wrong because we gotta handle links with this old host.
   * You'll see that the new host (commerzbank.de/service) case is handled down below
   */
  'cds-wissen': 'service.commerzbank.de',
  'cds-wissen-gpp-de': 'service-firmenkunden.commerzbank.com',
  'cds-wissen-gpp-en': 'service-corporates.commerzbank.com',
};

const bp = process.env.NEXT_PUBLIC_BASEPATH || '';

const basename = process.env.NEXT_PUBLIC_WEBAPP_BASENAME || 'cds-wissen';

export const currentHost = hosts[basename as Metadata['basename']];

export function sanitizeHref(href?: string) {
  if (!href) return '';
  if (isExceptionalLink(href)) {
    return href;
  }
  if (href.startsWith('/')) {
    return href;
  }
  let newHref = href;
  if (newHref.startsWith('http://')) {
    newHref = newHref.slice(7);
  }
  if (newHref.startsWith('https://')) {
    newHref = newHref.slice(8);
  }
  return 'https://' + newHref;
}

export function isInternalLink(href?: string, basePath: string = bp): boolean {
  if (!href) {
    return false;
  }
  href = href.trim();
  if (href.startsWith('/')) {
    return true;
  }

  const cleanHref = sanitizeHref(href ?? '');

  const targetUrl = new URL(cleanHref, `https://${currentHost}`);

  if (
    targetUrl.host === currentHost ||
    targetUrl.host === 'www.' + currentHost
  ) {
    return true;
  }

  const bpLength = basePath.length;
  const cobaHost = 'commerzbank.de';

  if (
    bpLength > 0 &&
    (targetUrl.host === cobaHost || targetUrl.host === 'www.' + cobaHost) &&
    targetUrl.pathname.startsWith(basePath)
  ) {
    // handle commerzbank.de/<base-path>
    return targetUrl.pathname.slice(0, bpLength) === basePath;
  }

  return false;
}

export function sanitizeInternalLink(href?: string, basePath = bp) {
  const cleanHref = sanitizeHref(href ?? '');
  const url = new URL(cleanHref ?? '', `https://${currentHost}`);

  const bpLength = basePath.length;
  if (url.pathname.startsWith(basePath)) {
    const newHref = url.pathname.slice(bpLength);
    return slashify(newHref);
  }
  return slashify(url.pathname);
}
/**
 * note(filipe): this is for SEO purposes. The navigation should be handled by
 * either the DynamicLink component or by using the navigate function inside the event listener. However,
 * components like Card, ClickItem and others might need a href prop for SEO purposes
 * and that's where this helper comes in hand.
 */

export function dynamicHref(href: string = '', basePath: string = bp) {
  if (isInternalLink(href, basePath)) {
    const newHref = sanitizeInternalLink(href, basePath);
    if (href.startsWith(basePath)) {
      return newHref;
    }
    return basePath + newHref;
  }

  return href;
}

export function nextLinkDynamicHref(href: string = '') {
  return slashify(sanitizeInternalLink(href));
}

export type MakeLinkFunc = (
  subdomain: 'kunden' | 'www',
  path: string | Record<DeploymentStage, string>,
) => string;

function sanitizePath(path: string): string {
  if (!path.startsWith('/')) {
    return `/${path}`;
  }

  return path;
}

function getBase(host: string): string {
  const allowedSubdomains = ['www', 'kunden'];

  const delimiter = host.indexOf('.');
  const sub = host.substring(0, delimiter);
  const base = host.substring(delimiter + 1);

  if (sub.length === 0 || !allowedSubdomains.includes(sub)) {
    return host;
  }
  return base;
}

export function useMakeLink(deploymentStage: DeploymentStage): MakeLinkFunc {
  const { host: publicHost } = new URL(process.env.NEXT_PUBLIC_HOST ?? '');
  const [base, setBase] = useState(getBase(publicHost));

  useEffect(() => {
    const { host } = new URL(window.location.href);
    const base = getBase(host);
    setBase(base);
  }, []);

  return function makeLink(subdomain, path) {
    if (typeof path === 'string') {
      return `https://${subdomain}.${base}${sanitizePath(path)}`;
    }

    return `https://${subdomain}.${base}${sanitizePath(path[deploymentStage])}`;
  };
}
