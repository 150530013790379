import create from 'zustand';

import {
  StartResponse,
  StateChangedMessage,
  TextMessage,
} from '../../../chatbot-api';

export type UserStateType = {
  participantId: string;
  displayName: string;
  hasConsented: boolean;
  status: 'online' | 'offline' | 'reconnected';
  state: -1 | StateChangedMessage['state'];
};

type QuickReplyContent = {
  id: string;
  type: string;
  action: string;
  text: string;
};

// THIS IS A PLACEHOLDER
export type StructuredMessage = {
  __type: string;
  text: string;
  contentType: string;
  content: QuickReplyContent[];
  timestamp: string;
  participantId: string;
  sequenceNumber: number;
};

export type ChatMessageType = TextMessage & {
  displayName: string;
};

export type AgentState = {
  agents: Record<string, string>;
  state: number;
};

export type ChatStateState = StartResponse & {
  workgroupName?: string;
};

export type ChatState = {
  chatState: ChatStateState;
  agentState: AgentState;
  userState: UserStateType;
  msgs: ChatMessageType[];
  temporaryMsgs: ChatMessageType[];
  unsentMsgs: ChatMessageType[];
  quickReplies: QuickReplyContent[];
  isChatOpen: boolean;
  isChatMinimized: boolean;
  isChatBotTyping: boolean;
  unreadMsgs: number;
  setChatState: (state: Partial<ChatStateState>) => void;
  setAgentState: (state: AgentState) => void;
  setUser: (state: Partial<UserStateType>) => void;
  setMsgs: (msgs: ChatMessageType[]) => void;
  setTemporaryMsgs: (temporaryMsgs: ChatMessageType[]) => void;
  setUnsentMsgs: (temporaryMsgs: ChatMessageType[]) => void;
  setQuickReplies: (qr: QuickReplyContent[]) => void;
  setIsChatOpen: (b: boolean) => void;
  setIsChatMinimized: (b: boolean) => void;
  setIsChatBotTyping: (b: boolean) => void;
  setUnreadMsgs: (n: number) => void;
};

export const useChatStore = create<ChatState>((set, get) => ({
  chatState: {
    chatId: '',
    pollWaitSuggestion: undefined,
    workgroupName: undefined,
  },
  agentState: {
    agents: {
      '00000000-0000-0000-0000-000000000000': 'SYSTEM',
    },
    state: 0,
  },
  userState: {
    participantId: '',
    displayName: '',
    hasConsented: false,
    status: 'online',
    state: 0, // -1 error connecting / 0 - connecting / 1 - alerted about chat session / 2 - active / 3 - on hold / 4 - disconnected
  },
  msgs: [],
  temporaryMsgs: [],
  unsentMsgs: [],
  quickReplies: [],
  isChatMinimized: false,
  isChatBotTyping: false,
  unreadMsgs: 0,
  isChatOpen: false,
  setChatState: (chatState) =>
    set((state) => ({ chatState: { ...state.chatState, ...chatState } })),
  setAgentState: (agentState) => set((state) => ({ agentState })),
  setUser: (userState) =>
    set((state) => ({ userState: { ...state.userState, ...userState } })),
  setMsgs: (msgs) => set((state) => ({ msgs: [...state.msgs, ...msgs] })),
  setTemporaryMsgs: (temporaryMsgs) => set((state) => ({ temporaryMsgs })),
  setUnsentMsgs: (unsentMsgs) => set((state) => ({ unsentMsgs })),
  setQuickReplies: (quickReplies) => set((state) => ({ quickReplies })),
  setIsChatOpen: (b) => set((state) => ({ isChatOpen: b })),
  setIsChatMinimized: (b) => set((state) => ({ isChatMinimized: b })),
  setIsChatBotTyping: (b) => set((state) => ({ isChatBotTyping: b })),
  setUnreadMsgs: (n) => set((state) => ({ unreadMsgs: n })),
}));

export const selectChatProps = (state: ChatState) => ({
  chatState: state.chatState,
  agentState: state.agentState,
  userState: state.userState,
  msgs: state.msgs,
  temporaryMsgs: state.temporaryMsgs,
  unsentMsgs: state.unsentMsgs,
  quickReplies: state.quickReplies,
  isChatOpen: state.isChatOpen,
  isChatMinimized: state.isChatMinimized,
  isChatBotTyping: state.isChatBotTyping,
  unreadMsgs: state.unreadMsgs,
});

export const selectChatActions = (state: ChatState) => ({
  setChatState: state.setChatState,
  setAgentState: state.setAgentState,
  setUser: state.setUser,
  setMsgs: state.setMsgs,
  setTemporaryMsgs: state.setTemporaryMsgs,
  setUnsentMsgs: state.setUnsentMsgs,
  setQuickReplies: state.setQuickReplies,
  setIsChatOpen: state.setIsChatOpen,
  setIsChatMinimized: state.setIsChatMinimized,
  setIsChatBotTyping: state.setIsChatBotTyping,
  setUnreadMSgs: state.setUnreadMsgs,
});
