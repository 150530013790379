import { AspectRatio, HStack, VStack } from '@neui/layout';
import { Headline } from '@neui/styleguide-commerzbank';

import ChainIcon from './chain-icon';

type StructuredButtonMessageProps = {
  image?: string;
  title?: string;
  component: {
    actions: {
      url: string;
      urlTarget: '_blank' | '_self';
    };
    id: string;
    text: string;
    type: string; // button
  };
};
export function StructuredButtonMessage({
  image,
  title,
  component,
}: StructuredButtonMessageProps) {
  return (
    <>
      {image && (
        <AspectRatio
          aspectRatio={'16 / 9'}
          onClick={() =>
            window.open(component.actions.url, component.actions.urlTarget)
          }
        >
          {<img src={image} alt={title} />}
        </AspectRatio>
      )}
      <HStack
        spacing={24}
        alignItems={'center'}
        css={{ padding: '16px' }}
        onClick={() =>
          window.open(component.actions.url, component.actions.urlTarget)
        }
      >
        <VStack>
          <Headline type={'h6'} css={{ width: 224 }}>
            {component.text}
          </Headline>
        </VStack>
        <ChainIcon />
      </HStack>
    </>
  );
}
