import { AsProps, CSSProps, styled } from '@neui/core';
import { HStack, VStack } from '@neui/layout';
import { FC, HTMLAttributes } from 'react';

import { baseTheme, sandTheme, oceanTheme } from 'styling/stitches.config';

type AvailableThemes = 'light' | 'sand' | 'ocean';

export type SectionProps = HTMLAttributes<HTMLDivElement> &
  CSSProps &
  AsProps & {
    theme?: AvailableThemes;
  };

function getTheme(theme: AvailableThemes) {
  switch (theme) {
    case 'light':
      return baseTheme;
    case 'sand':
      return sandTheme;
    case 'ocean':
      return oceanTheme;
  }
}

const Wrapper = styled(VStack, {
  display: 'flex',
  justifyContent: 'center',
  background: '$colors$neutral0',
  paddingY: 'calc($section / 2)',
  paddingX: '28px',
  '@sm': {
    paddingX: 86,
  },
  '@md': {
    paddingY: 'calc($double-subsection + 20px)',
    paddingX: '112px',
  },
});

const Content = styled(VStack, {
  width: '100%',
  maxWidth: 480,
  overflow: 'visible',
  '@xs': {
    maxWidth: 768,
  },
  '@md': {
    maxWidth: 1024,
  },
  '@lg': {
    maxWidth: 1440,
  },
});

export const Section: FC<SectionProps> = ({
  theme = 'light',
  renderAs = 'section',
  children,
  ...rest
}) => {
  return (
    <Wrapper
      as={renderAs}
      className={`${baseTheme} ${getTheme(theme)}`}
      {...rest}
    >
      <Content spacing="$subsection">{children}</Content>
    </Wrapper>
  );
};
