import { CSSProps, styled } from '@neui/core';
import { Box } from '@neui/layout';

type SectionSeparatorProps = {
  styles?: CSSProps;
};

export const SectionSeparator = ({ styles }: SectionSeparatorProps) => {
  const style = styles ? { ...styles, width: '100%' } : { width: '100%' };
  return <Separator css={style} />;
};

const Separator = styled(Box, {
  marginTop: 24,
  height: 1,
  backgroundColor: '$colors$text-disabled',
  width: '100%',
});
