import { Typography } from '@neui/styleguide-commerzbank';

import { WarningScreen } from './WarningScreen';
import { selectChatActions, useChatStore } from '../store/store';

export function NotAvailableScreen() {
  const { setIsChatOpen } = useChatStore(selectChatActions);
  return (
    <WarningScreen
      imgSrc="/service/errorIcon.svg"
      imgAlt="Digitaler Assistent ist nicht verfügbar"
      headline="Digitaler Assistent ist nicht verfügbar"
      buttonLabel="Schließen"
      onButtonClick={() => setIsChatOpen(false)}
    >
      <Typography size={6} textAlign={'center'}>
        Wir bitten Sie zu entschuldigen, dass diese Funktion vorübergehend nicht
        läuft. Bitte versuchen Sie es etwas später nochmal.
      </Typography>
    </WarningScreen>
  );
}
