//@ts-nocheck
import {
  StateChangedMessage,
  TextMessage,
  TypingIndicatorMessage,
} from 'chatbot-api';

import { apiClient } from '../ChatWindow';
import { ChatMessageType, ChatState } from '../store/store';

type CookieBoxConsent = {
  consentStatus: boolean;
  dataProcessingService: string;
  templateId: string;
};

type CookieBoxConsentList = Record<string, CookieBoxConsent>;

type CookieBox = {
  isInitialised: boolean;

  /**
   * Öffnet Banner (First-Layer)
   */
  toggleBannerIsVisible: () => void;

  /**
   * Öffnet Settings (Second-Layer)
   */
  toggleSettingsAreVisible: () => void;

  /**
   * Gibt alle Consents als Objekt zurück
   */
  // getConsents: () => CookieBoxConsentList;

  /**
   * Gibt Consent als Objekt für bestimmten Service anhand der Service-ID zurück
   */
  getConsents: (serviceId: string) => CookieBoxConsent;

  /**
   * Aktualisiert den Consent-Status für einen bestimmten Service anhand der Service-ID
   */
  updateConsents: (params: { templateId: string; status: boolean }) => void;

  /**
   * Akzeptiert alle Services
   */
  acceptAllConsents: () => void;

  /**
   * Lehnt alle Services ab
   */
  denyAllConsents: () => void;
};

declare global {
  interface Window {
    ccb_cif: {
      pToken: string;
    };

    cookieBox?: CookieBox;
  }
}

export type ChatMessageUnion =
  | TextMessage
  | StateChangedMessage
  | TypingIndicatorMessage;

export function isTextMessage(msg: ChatMessageUnion): msg is TextMessage {
  return msg.__type === 'urn:inin.com:webChat:chatTextMessage';
}

export function isTypingIndicatorMessage(
  msg: ChatMessageUnion,
): msg is TypingIndicatorMessage {
  return msg.__type === 'urn:inin.com:webChat:chatTypingIndicatorMessage';
}

export function isStateChangedMessage(
  msg: ChatMessageUnion,
): msg is StateChangedMessage {
  return (
    msg.__type === 'urn:inin.com:webChat:chatParticipantStateChangedMessage'
  );
}

type StructuredMessage = {
  type: 'Structured';
};
export type QuickReplyStructuredMessage = StructuredMessage & {
  contentType: 'quick-replies';
  content: {
    action: string;
    id: string;
    text: string;
    type: 'quick-reply';
  }[];
};

export type GenericComponentType = {
  actions: {
    url: string;
    urlTarget: '_blank' | '_self';
  };
  id: string;
  text: string;
  type: string;
};

export type GenericStructuredMessage = StructuredMessage & {
  contentType: 'generic';
  content: {
    desc: string;
    title?: string;
    image?: string;
    id: string;
    components?: GenericComponentType[];
  }[];
};

export function isQuickReplyMessageContent(
  value: QuickReplyStructuredMessage | GenericStructuredMessage | string,
): value is QuickReplyStructuredMessage {
  return typeof value !== 'string' && value.contentType === 'quick-replies';
}

export function isGenericMessageContent(
  value: QuickReplyStructuredMessage | GenericStructuredMessage | string,
): value is GenericStructuredMessage {
  return typeof value !== 'string' && value.contentType === 'generic';
}

// we might still need this in the future
export async function onChatBotInitiate() {
  try {
    const res = await apiClient.chat.initiateChatUsingPost(
      {
        PlaceWhereCallWasInitiated: '91101',
        Language: 'L01', // Deutsch
        'CDS-AppVersion': '0.0.1',
      },
      {
        headers: {
          'Content-Type': 'application/json',
          pToken: window?.ccb_cif?.pToken ?? '',
        },
      },
    );

    const data = res.data.result?.data;

    if (res.status !== 200 || !data) {
      throw new Error(`Couldn't initiate chatbot. Status ${res.status}`);
    }

    return data.mobileAppAvailability?.workgroupName; // this will be used on startChat
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function onChatBotStart(workGroupName?: string) {
  try {
    const res = await apiClient.chat.startChatUsingPost(
      {
        participantName: 'USER',
        targetName: workGroupName ?? '',
        additionalAttributes: {
          'CDS-PlaceWhereCallWasInitiated': '91101',
          'CDS-Language': 'L01',
          'CDS-Channel': 'WEB',
          'CDS-AppVersion': 'CDSApp-0.0.1',
          'CDS-OS': 'CDS-OS',
          'CDS-Device': 'CDS-Device',
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          pToken: window?.ccb_cif?.pToken ?? '',
        },
      },
    );

    const data = res?.data?.result?.data;

    if (res.status !== 200 || !data) {
      throw new Error(`Couldn't start chatbot. Status ${res.status}`); // how are we handling this?
    }

    return data;
  } catch (err: any) {
    throw new Error(err);
  }
}

type startChatArgs = {
  setChatState: ChatState['setChatState'];
  setUser: ChatState['setUser'];
  chatState: ChatState['chatState'];
};

export async function startChat({
  setChatState,
  setUser,
  chatState,
}: startChatArgs) {
  if (chatState.chatId) {
    // avoid duplicate start
    return;
  }
  try {
    const data = await onChatBotStart(chatState?.workgroupName);
    setChatState({
      chatId: data.chatId,
      pollWaitSuggestion: data.pollWaitSuggestion,
    });
    setUser({
      participantId: data.participantId ?? '',
    });
  } catch (e) {
    console.error(e);
    setUser({ chatStatus: -1 });
  }
}
interface RedirectError extends Error {
  name: 'RedirectError';
}

const RedirectError = (msg: string) => {
  const error = new Error(msg) as RedirectError;
  error.name = 'RedirectError';
  return error;
};

export const isRedirectError = (error: unknown): error is RedirectError =>
  error.name === 'RedirectError';

export async function pollMsgs(
  participantId: string,
): Promise<ChatMessageUnion[]> {
  try {
    const res = await apiClient.chat.messagesUsingPost(
      { participantId },
      {
        headers: {
          'Content-Type': 'application/json',
          pToken: window?.ccb_cif?.pToken ?? '',
        },
      },
    );

    if (res.redirected) {
      throw RedirectError('response was redirected, probably logged out');
    }

    if (res.status !== 200) {
      throw new Error(`Couldn't get chatbot messages. Status ${res.status}`);
    }
    const msgs = res?.data?.result?.data?.messages;
    if (!msgs?.length) {
      return [];
    }
    return msgs as ChatMessageUnion[];
  } catch (err: any) {
    throw err;
  }
}

type createuserMessageArgs = {
  userInput: string;
  participantId: string;
  displayName: string;
  msgsLength: number;
};

export function createUserMessage({
  userInput,
  participantId,
  displayName,
  msgsLength,
}: createuserMessageArgs): ChatMessageType {
  return {
    __type: 'urn:inin.com:webChat:chatTextMessage',
    contentType: 'text/plain',
    participantId,
    displayName,
    value: userInput,
    timestamp: new Date().toString(),
    sequenceNumber: msgsLength,
  };
}

type ParsedMsg = {
  isJson: boolean;
  value: string | QuickReplyStructuredMessage | GenericStructuredMessage;
};

export function parseMsgValue(value: ChatMessageType['value']): ParsedMsg {
  try {
    const parsedValue = JSON.parse(value);
    return {
      isJson: true,
      value: parsedValue,
    };
  } catch (e) {
    return {
      isJson: false,
      value,
    };
  }
}

export function formatMsgTime(timestamp: string) {
  const date = new Date(timestamp);
  const hrs = date.getHours();
  const formattedHrs = ('0' + hrs).slice(-2);
  const mins = date.getMinutes();
  const formattedMins = ('0' + mins).slice(-2);
  return formattedHrs + ':' + formattedMins;
}
