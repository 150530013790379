import { AsProps, CSSProps, styled } from '@neui/core';
import { Box, Flex, VStack } from '@neui/layout';
import { Typography } from '@neui/styleguide-commerzbank';
import { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';

import { bannerBadgeContainerStyle } from './BannerIconBadge';

export type BannerBadgeLook =
  | 'supplementary'
  | 'primary'
  | 'secondary-1'
  | 'secondary-2'
  | 'secondary-3'
  | 'secondary-4'
  | 'secondary-5'
  | 'secondary-6'
  | 'highlight'
  | 'optional'
  | 'inactive'
  | 'success'
  | 'alert'
  | 'error';

export type BannerTextBadgeProps = AsProps &
  CSSProps &
  HTMLAttributes<HTMLDivElement> &
  PropsWithChildren<{
    look?: BannerBadgeLook;
  }>;

const StyledTypography = styled(Typography, {
  color: 'inherit !important',
  fontSize: '$fontSizes$mobile-7 !important',
  lineHeight: '$lineHeights$mobile-7 !important',
  letterSpacing: '0.3px !important',
});

const Container = styled(Box, bannerBadgeContainerStyle, {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingY: 1,
  paddingX: '$component-2',
  borderRadius: '$space$component-2',
});

export const BannerTextBadge = forwardRef<HTMLDivElement, BannerTextBadgeProps>(
  ({ children, ...rest }, forwardedRef) => {
    return (
      <Flex>
        <Container ref={forwardedRef} {...rest}>
          <StyledTypography size={7} weight="medium">
            {children}
          </StyledTypography>
        </Container>
      </Flex>
    );
  },
);
BannerTextBadge.displayName = 'BannerTextBadge';
