import fetch from 'isomorphic-fetch';
export declare type FetchUtilResult<T> = {
  data: T[];
  error?: Error;
};
// export declare const fetchUtil:<T> (url: string, headers?: any, bodyData?: {
//   [key: string]: any;
// }, method?: "POST" | "GET", additionalOptions?: any) => Promise<FetchUtilResult<T>>;
export async function fetchUtil<T>(
  url: string,
  headers?: any,
  bodyData?: { [key: string]: any },
  method?: 'POST' | 'GET',
  additionalOptions?: any,
): Promise<FetchUtilResult<T>> {
  if (!url) {
    throw new Error('Missing URL param');
  }
  const fetchMethod = method || 'GET';
  const body =
    fetchMethod === 'POST' && bodyData
      ? JSON.stringify({
          ...bodyData,
        })
      : undefined;
  const applicationJSON = 'application/json';
  const options = {
    body,
    method,
    headers: headers || {
      Accept: applicationJSON,
      'Content-Type': applicationJSON,
    },
    ...additionalOptions,
  };
  let result;
  try {
    const response = await new Promise<Response>((resolve, reject) => {
      fetch(url, options)
        .then(resolve)
        .catch((e) => {
          // When the request is aborted the promise won't fulfill
          if (e.name !== 'AbortError') {
            reject(e);
          }
        });
    });
    if (!response.ok) {
      if (response.status === 500) result = 500;
      else {
        throw new Error(`Response not okay: ${response.statusText}`);
      }
    }
    if (
      options.headers.Accept === applicationJSON ||
      options.headers['Content-Type'] === applicationJSON
    ) {
      result = await response.json();
    } else {
      // future problems, implement text(), blob(), arrayBuffer() etc
      throw new Error('non-json responses are not yet implemented');
    }

    return { data: Array.isArray(result) ? [...result] : [result] };
  } catch (error: any) {
    console.error(error);
    return { error, data: [] };
  }
}
