import {
  object_nature_sprout,
  location___cobaTower,
  location___office,
  people___businessMale,
  communication___news,
} from '@neui/styleguide-commerzbank';
//actually Female
export const alternativePortalsGerman = [
  {
    icon: people___businessMale,
    url: 'https://www.commerzbank.de/',
    text: 'Privat- und Unternehmerkunden',
  },
  {
    icon: location___office,
    url: 'https://www.commerzbank.com/firmenkunden/',
    text: 'Firmenkunden',
  },
  {
    icon: location___cobaTower,
    url: 'https://www.commerzbank.de/konzern/ ',
    text: 'Konzern',
  },
  {
    icon: object_nature_sprout,
    url: 'https://www.commerzbank.de/nachhaltigkeit/',
    text: 'Nachhaltigkeit',
  },
  {
    icon: communication___news,
    url: ' https://investor-relations.commerzbank.com/de/',
    text: 'Investor Relations',
  },
];

export const alternativePortalsEnglish = [
  {
    icon: people___businessMale,
    url: 'https://www.commerzbank.de/',
    text: 'Private and Small Business Customers (German only)',
  },
  {
    icon: location___office,
    url: 'https://www.commerzbank.com/corporateclients/',
    text: 'Corporate Clients',
  },
  {
    icon: location___cobaTower,
    url: 'https://www.commerzbank.de/group/ ',
    text: 'Group',
  },
  {
    icon: object_nature_sprout,
    url: 'https://www.commerzbank.de/sustainability/',
    text: 'Sustainability',
  },
  {
    icon: communication___news,
    url: ' https://investor-relations.commerzbank.com/',
    text: 'Investor Relations',
  },
];
