import { forwardRef, HTMLAttributes } from 'react';
import { CSSProps, styled, VariantProps } from '@neui/core';
import { Box, HStack } from '@neui/layout';
import { interaction_arrows_chevronRight } from '@neui/styleguide-commerzbank';

import { GA4TrackNavigationClick } from '@utils/tracking';
import { useTracker } from '@utils/snowplowTracking';

import { Link } from './Link';
import { Icon } from './Icon';

const Wrapper = styled(Box, {
  listStyle: 'none',
  '&:first-child .breadcrumb-icon': {
    display: 'none',
  },
});

const StyledLink = styled(Link, {
  transition: 'transform 0.3s ease-in-out',
  '&:focus-visible:not([disabled])': {
    outline: '2px solid $text-standard',
    border: 'none',
    borderRadius: 4,
    outlineOffset: 4,
    transform: 'translate(4px, 0)',
  },
  '&:hover:not([disabled])': {
    transform: 'translate(4px, 0)',
  },
});
export type BreadcrumbsProps = HTMLAttributes<HTMLLIElement> &
  VariantProps<typeof Wrapper> &
  CSSProps & {
    label: string;
    target: string;
    disabled?: boolean;
  };

export const BreadcrumbsItem = forwardRef<HTMLLIElement, BreadcrumbsProps>(
  ({ target, label, disabled, ...rest }, forwardedRef): React.ReactElement => {
    const { trackButtonClick } = useTracker(BreadcrumbsItem.name);
    return (
      <Wrapper
        ref={forwardedRef}
        {...rest}
        as="li"
        onClick={() => {
          disabled !== true &&
            GA4TrackNavigationClick('navigationClick', 'Breadcrumb', label);
          trackButtonClick?.(label, 'navigation', []);
        }}
      >
        <HStack alignItems={'center'} spacing={8}>
          <Icon
            className="breadcrumb-icon"
            size="small"
            icon={interaction_arrows_chevronRight}
          />
          <StyledLink href={target} size={7} disabled={disabled}>
            {label}
          </StyledLink>
        </HStack>
      </Wrapper>
    );
  },
);

BreadcrumbsItem.displayName = 'BreadcrumbsItem';
