import NextLink from 'next/link';

import {
  isInternalLink,
  dynamicHref,
  nextLinkDynamicHref,
} from '@utils/helpers/linkHelpers';

import { Link, LinkProps } from '../neui-components/atoms/Link';

export function DynamicLink({ href, children, ...rest }: LinkProps) {
  if (isInternalLink(href)) {
    return (
      <NextLink href={nextLinkDynamicHref(href)} passHref>
        <Link {...rest} target="_self">
          {children}
        </Link>
      </NextLink>
    );
  }

  return (
    <Link {...rest} href={dynamicHref(href)} target="_self">
      {children}
    </Link>
  );
}
