import { symbols___info } from '@neui/styleguide-commerzbank';

import { WarningMessage } from './WarningMessage';

export function MessageNotSent() {
  return (
    <WarningMessage
      size="small"
      variant="solid"
      look={'primary'}
      icon={symbols___info}
      message="Nachricht nicht gesendet. Bitte aktualisieren Sie Ihre Nachricht."
    />
  );
}
