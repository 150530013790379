import { AsProps, CSSProps, styled } from '@neui/core';
import { HStack, VStack } from '@neui/layout';
import { forwardRef, HTMLAttributes, ReactNode } from 'react';

import { BannerIconBadge, BannerIconBadgeProps } from './BannerIconBadge';
import { BannerTextBadge, BannerTextBadgeProps } from './BannerTextBadge';

export type BannerContentProps = AsProps &
  CSSProps &
  BannerIconBadgeProps &
  BannerTextBadgeProps &
  HTMLAttributes<HTMLDivElement> & {
    customIcon?: ReactNode;
    badgeContent?: string | ReactNode;
    actions?: ReactNode;
  };

const Container = styled(HStack, {
  width: '100%',
});

export const BannerContent = forwardRef<HTMLDivElement, BannerContentProps>(
  (
    {
      customIcon,
      icon,
      badgeContent,
      variant = 'outline',
      look = 'supplementary',
      actions,
      size,
      children,
      ...rest
    },
    forwardedRef,
  ) => {
    return (
      <Container
        ref={forwardedRef}
        alignItems="center"
        spacing="$component-4"
        {...rest}
      >
        {customIcon ? (
          customIcon
        ) : icon ? (
          <BannerIconBadge
            size={size}
            variant={variant}
            icon={icon}
            look={look}
          />
        ) : (
          <></>
        )}
        <VStack spacing="$component-4" css={{ width: '100%' }}>
          {badgeContent ? (
            <VStack alignItems="flex-start">
              <BannerTextBadge look={look}>{badgeContent}</BannerTextBadge>
              {children}
            </VStack>
          ) : (
            children
          )}
          {actions && (
            <HStack justifyContent="flex-end" spacing="$component-6">
              {actions}
            </HStack>
          )}
        </VStack>
      </Container>
    );
  },
);
BannerContent.displayName = 'BannerContent';
