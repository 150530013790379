import { DomElement } from 'html-react-parser';

import { CDS_TEST_URLS } from '@utils/constants';

export function toBoolean(input?: string): boolean | undefined {
  if (input === undefined) {
    return undefined;
  }

  switch (input.toLowerCase()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    default:
      return false;
  }
}

const umlautsMap: { [key: string]: string } = {
  ä: 'ae',
  ö: 'oe',
  ü: 'ue',
  ß: 'ss',
};

export function replaceUmlauts(str: string): string {
  return str.replace(/[äöüß]/g, (c) => umlautsMap[c] || c);
}

// function to deliver specific keywords from user feedback responses to GA4.
export function findSubstrings(
  strings: Set<string>,
  searchTerms: string[],
): string[] {
  const searchSet = new Set(searchTerms.map((term) => term.toLowerCase()));
  const foundTerms = new Set<string>();

  strings.forEach((str) => {
    const lowerStr = str.toLowerCase();
    searchSet.forEach((term) => {
      if (lowerStr.includes(term) && !foundTerms.has(term)) {
        foundTerms.add(term);
      }
    });
  });

  return Array.from(foundTerms);
}

const translationMap: { [key: string]: string } = {
  unverständlich: 'not_understandable',
  'passt nicht': 'does_not_match',
  unvollständig: 'incomplete',
  undeutlich: 'not_clear',
  sonstiges: 'others',
};

// function to deliver user feedback responses as the desired key, value object to BG
export function createResultObject(
  substrings: string[],
  foundSubstrings: string[],
): { [key: string]: boolean } {
  const lowercasedFoundSet = new Set(
    foundSubstrings.map((term) => term.toLowerCase()),
  );

  return substrings.reduce(
    (result, substr) => {
      const translation = translationMap[substr] || substr;
      result[translation] = lowercasedFoundSet.has(substr.toLowerCase());
      return result;
    },
    {} as { [key: string]: boolean },
  );
}

const baseUrlPatterns = CDS_TEST_URLS.map((url) => new RegExp(url));

function extractSlugFromUrl(url: string): string | null {
  for (const pattern of baseUrlPatterns) {
    if (pattern.test(url)) {
      const urlObj = new URL(url);
      return urlObj.pathname;
    }
  }
  return null;
}

export function getFullUrlWithSlug(url: string): string | null {
  const slug = extractSlugFromUrl(url);
  if (slug !== null) {
    if (isTUC()) return `${window.location.origin}/service${slug}`;
    return `${window.location.origin}${slug}`;
  }
  return url;
}

export function isTUC(): boolean {
  if (typeof window === 'undefined') {
    return false;
  }
  const currentHostname = window.location.hostname;
  const tucPattern = /^.*ccb-tuc\d{2}\.cloud\.internal$/;
  const twgiPattern = /^.*ccb-twgi\.intranet\.commerzbank\.com$/;

  return tucPattern.test(currentHostname) || twgiPattern.test(currentHostname);
}

export const findFirstDataNode = (domNode: DomElement): string | null =>
  domNode.data ||
  domNode.children?.map(findFirstDataNode).find(Boolean) ||
  null;
