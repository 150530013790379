/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * ChatMessage
 * Base abstract class of chat-messages (see also: StateChangedMessage, TextMessage, TypingIndicatorMessage)
 */
export interface ChatMessage {
  /** String indicating the message class */
  __type: 'StateChangedMessage' | 'TextMessage' | 'TypingIndicatorMessage';
  /** The participant ID associated with the message */
  participantId: string;
  /**
   * The sequence number of the message in the context of the chat session
   * @format int32
   */
  sequenceNumber: number;
  /**
   * The UTC timestamp representing when the event occurred
   * @format date-time
   */
  timestamp: string;
  value: string;
  state: 0 | 1 | 2 | 3 | 4;
}

/** InitiateRequest */
export interface InitiateRequest {
  'CDS-AppVersion': string;
  Language: string;
  PlaceWhereCallWasInitiated: string;
}

/** InitiateResponse */
export interface InitiateResponse {
  mobileAppAvailability?: MobileAppAvailability;
}

/** JsonError */
export interface JsonError {
  cancelling?: boolean;
  errors?: Message[];
}

/** JsonMetaData */
export interface JsonMetaData {
  globalRequestId?: string;
  processContextId?: string;
}

/** JsonResponse */
export interface JsonResponse {
  error?: JsonError;
  metaData?: JsonMetaData;
  result?: JsonResultOfobject;
}

/** JsonResultOfInitiateResponse */
export interface JsonResultOfInitiateResponse {
  data?: InitiateResponse;
  hints?: Message[];
}

/** JsonResultOfMessagesResponse */
export interface JsonResultOfMessagesResponse {
  data?: MessagesResponse;
  hints?: Message[];
}

/** JsonResultOfSendMessageResponse */
export interface JsonResultOfSendMessageResponse {
  data?: SendMessageResponse;
  hints?: Message[];
}

/** JsonResultOfStartResponse */
export interface JsonResultOfStartResponse {
  data?: StartResponse;
  hints?: Message[];
}

/** JsonResultOfTypingStateResponse */
export interface JsonResultOfTypingStateResponse {
  data?: TypingStateResponse;
  hints?: Message[];
}

/** JsonResultOfUnreadMessagesResponse */
export interface JsonResultOfUnreadMessagesResponse {
  data?: UnreadMessagesResponse;
  hints?: Message[];
}

/** JsonResultOfobject */
export interface JsonResultOfobject {
  data?: object;
  hints?: Message[];
}

/** Message */
export interface Message {
  messageId?: string;
}

/** MessagesRequest */
export interface MessagesRequest {
  /** The participant ID of the participant which the request is being made on behalf of */
  participantId: string;
}

/** MessagesResponse */
export interface MessagesResponse {
  /** List of chat messages */
  messages?: ChatMessage[];
  /**
   * The suggested interval (in milliseconds) at which the client should make polling requests about the chat session
   * @format int32
   */
  pollWaitSuggestion?: number;
}

/** MobileAppAvailability */
export interface MobileAppAvailability {
  workgroupName?: string;
}

/** PostExitResponse */
export interface PostExitResponse {
  error?: JsonError;
  metaData?: JsonMetaData;
  result?: JsonResultOfUnreadMessagesResponse;
}

/** PostInitiateTextchatResponse */
export interface PostInitiateTextchatResponse {
  error?: JsonError;
  metaData?: JsonMetaData;
  result?: JsonResultOfInitiateResponse;
}

/** PostMessageHistoryResponse */
export interface PostMessageHistoryResponse {
  error?: JsonError;
  metaData?: JsonMetaData;
  result?: JsonResultOfUnreadMessagesResponse;
}

/** PostMessagesResponse */
export interface PostMessagesResponse {
  error?: JsonError;
  metaData?: JsonMetaData;
  result?: JsonResultOfMessagesResponse;
}

/** PostSendMessageResponse */
export interface PostSendMessageResponse {
  error?: JsonError;
  metaData?: JsonMetaData;
  result?: JsonResultOfSendMessageResponse;
}

/** PostStartResponse */
export interface PostStartResponse {
  error?: JsonError;
  metaData?: JsonMetaData;
  result?: JsonResultOfStartResponse;
}

/** PostTypingStateResponse */
export interface PostTypingStateResponse {
  error?: JsonError;
  metaData?: JsonMetaData;
  result?: JsonResultOfTypingStateResponse;
}

/** SendMessageRequest */
export interface SendMessageRequest {
  /** The content of the chat message */
  message: string;
  /** The participant ID of the participant which the request is being made on behalf of */
  participantId: string;
}

/** SendMessageResponse */
export interface SendMessageResponse {
  /**
   * Always set to 204 for successfully updated typing state
   * @format int32
   */
  status?: 204;
}

/** StartRequest */
export interface StartRequest {
  /**
   * attribute/value pairs
   * @example [{"attribute1":"String","value1":"String"}]
   */
  additionalAttributes?: object;
  participantName: string;
  targetName: string;
}

/** StartResponse */
export interface StartResponse {
  /** The ID of the chat session that was created. This should be stored by the client and used if a reconnect needs to occur */
  chatId?: string;
  /** The participant ID which was generated on behalf of the client making the request. This should be stored by the client and used for subsequent requests to endpoints that require a participant ID */
  participantId?: string;
  /**
   * The suggested interval (in milliseconds) at which the client should make polling requests about the chat session
   * @format int32
   */
  pollWaitSuggestion?: number;
}

/**
 * StateChangedMessage
 * Provides data for a participant state change message. This indicates that the connection state of a participant changed
 */
export type StateChangedMessage = ChatMessage & {
  /** String indicating the message class */
  __type: 'StateChangedMessage';
  /** The display name of the user that sent the message */
  displayName: string;
  /** The participant ID associated with the message */
  participantId: string;
  /** The type of user that sent the message. This will either be Agent, WebUser or System */
  participantType: string;
  /**
   * The sequence number of the message in the context of the chat session
   * @format int32
   */
  sequenceNumber: number;
  /**
   * The connection state of the participant
   * @format int32
   */
  state: 0 | 1 | 2 | 3 | 4;
  /** The UTC timestamp representing when the event occurred */
  timestamp: string;
};

/**
 * TextMessage
 * Provides data for a chat text message. This indicates that a user sent message to the chat
 */
export type TextMessage = ChatMessage & {
  /** String indicating the message class */
  __type: 'TextMessage';
  /** The content type of the chat message. This will either be "text/plain" or "text/html" */
  contentType: string;
  /** The participant ID associated with the message */
  participantId: string;
  /**
   * The sequence number of the message in the context of the chat session
   * @format int32
   */
  sequenceNumber: number;
  /** The UTC timestamp representing when the event occurred */
  timestamp: string;
  /** The value of the message that was sent */
  value: string;
};

/**
 * TypingIndicatorMessage
 * Provides data for a typing indicator message. This indicates whether a user started or stopped typing into the chat
 */
export type TypingIndicatorMessage = ChatMessage & {
  /** String indicating the message class */
  __type: 'TypingIndicatorMessage';
  /** The participant ID associated with the message */
  participantId: string;
  /**
   * The sequence number of the message in the context of the chat session
   * @format int32
   */
  sequenceNumber: number;
  /** The UTC timestamp representing when the event occurred */
  timestamp: string;
  /** Indicates whether the user started or stopped typing. A value of true indicates that typing started. A value of false indicates that typing stopped */
  value: boolean;
};

/** TypingStateRequest */
export interface TypingStateRequest {
  /** The participant ID of the participant which the request is being made on behalf of */
  participantId: string;
  /** Indicates whether or not the participant is typing */
  typingIndicator: boolean;
}

/** TypingStateResponse */
export interface TypingStateResponse {
  /**
   * Always set to 204 for successfully updated typing state
   * @format int32
   */
  status?: 204;
}

/** UnreadMessagesResponse */
export interface UnreadMessagesResponse {
  /** List of chat messages */
  messages?: ChatMessage[];
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '//localhost:8080';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === 'number' ? value : `${value}`
    )}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => 'undefined' !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string'
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${
        queryString ? `?${queryString}` : ''
      }`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {}),
        },
        signal:
          (cancelToken
            ? this.createAbortSignal(cancelToken)
            : requestParams.signal) || null,
        body:
          typeof body === 'undefined' || body === null
            ? null
            : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Api Documentation
 * @version 1.0
 * @license Apache 2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 * @termsOfService urn:tos
 * @baseUrl //localhost:8080
 * @contact
 *
 * Api Documentation
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  chat = {
    /**
     * @description Disconnects a participant from a chat and retrieves any queued web chat messages from the server.
     *
     * @tags textchat-app-v-1-controller-impl
     * @name ExitUsingPost
     * @summary exit
     * @request POST:/chat/web/v1/exit
     */
    exitUsingPost: (request: MessagesRequest, params: RequestParams = {}) =>
      this.request<PostExitResponse, void>({
        path: `/chat/web/v1/exit`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Establishes a new chat session.
     *
     * @tags textchat-app-v-1-controller-impl
     * @name InitiateChatUsingPost
     * @summary initiate
     * @request POST:/chat/web/v1/initiate
     */
    initiateChatUsingPost: (
      request: InitiateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PostInitiateTextchatResponse, void>({
        path: `/chat/web/v1/initiate`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves all web chat message representations from the server.
     *
     * @tags textchat-app-v-1-controller-impl
     * @name MessageHistoryUsingPost
     * @summary message-history
     * @request POST:/chat/web/v1/message-history
     */
    messageHistoryUsingPost: (
      request: MessagesRequest,
      params: RequestParams = {}
    ) =>
      this.request<PostMessageHistoryResponse, void>({
        path: `/chat/web/v1/message-history`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves any queued web chat message representations from the server.
     *
     * @tags textchat-app-v-1-controller-impl
     * @name MessagesUsingPost
     * @summary messages
     * @request POST:/chat/web/v1/messages
     */
    messagesUsingPost: (request: MessagesRequest, params: RequestParams = {}) =>
      this.request<PostMessagesResponse, void>({
        path: `/chat/web/v1/messages`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Sends a chat message from the participant.
     *
     * @tags textchat-app-v-1-controller-impl
     * @name SendMessageUsingPost
     * @summary send-message
     * @request POST:/chat/web/v1/send-message
     */
    sendMessageUsingPost: (
      request: SendMessageRequest,
      params: RequestParams = {}
    ) =>
      this.request<PostSendMessageResponse, void>({
        path: `/chat/web/v1/send-message`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Starts the chat.
     *
     * @tags textchat-app-v-1-controller-impl
     * @name StartChatUsingPost
     * @summary start
     * @request POST:/chat/web/v1/start
     */
    startChatUsingPost: (request: StartRequest, params: RequestParams = {}) =>
      this.request<PostStartResponse, void>({
        path: `/chat/web/v1/start`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the typing state for a participant.
     *
     * @tags textchat-app-v-1-controller-impl
     * @name TypingStateUsingPost
     * @summary typing-state
     * @request POST:/chat/web/v1/typing-state
     */
    typingStateUsingPost: (
      request: TypingStateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PostTypingStateResponse, void>({
        path: `/chat/web/v1/typing-state`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
