import React from 'react';
import { useRouter } from 'next/router';

import { WidgetName } from '@utils/snowplowTracking';
import { isOnlineBanking } from '@utils/WidgetChecker';
import { navigate } from '@utils/navigate';

import { ICallToAction } from './types';
import { WidgetActionButton } from './WidgetActionButton';

const OnlineBankWidget = ({
  ctas,
  widgetName,
}: {
  ctas: Array<ICallToAction>;
  widgetName: WidgetName;
}): JSX.Element => {
  const onlineBanking = ctas.find((x) => isOnlineBanking(x));
  const router = useRouter();
  if (!onlineBanking) {
    return <></>;
  }

  const { url, cta, icon, badgeIcon } = onlineBanking;

  return (
    <>
      <WidgetActionButton
        label={cta}
        url={url}
        icon={icon}
        badgeIcon={badgeIcon}
        widgetName={widgetName}
        onClick={() => navigate(url ?? '', router)}
      />
    </>
  );
};

export default OnlineBankWidget;
