import { CSSProps, styled } from '@neui/core';
import { forwardRef, HTMLAttributes } from 'react';
import {
  interaction_arrows_chevronLeft,
  interaction_arrows_chevronRight,
} from '@neui/styleguide-commerzbank';
import { HStack } from '@neui/layout';

import { LinkProps } from '../atoms/Link';
import { IconLink } from '../atoms/IconLink';
import { PaginationButton } from '../atoms/PaginationButton';

type PaginationData = LinkProps & {
  pageId: number;
};

export type PaginationProps = HTMLAttributes<HTMLDivElement> &
  CSSProps & {
    activePage: number;
    numPages: number;
    paginationData?: PaginationData[];
    onPageClick?: (page: number, e?: any) => void;
  };

export const Pagination = forwardRef<HTMLDivElement, PaginationProps>(
  (
    {
      activePage = 1,
      numPages,
      paginationData,
      onPageClick = () => {},
      ...rest
    },
    forwardedRef,
  ) => {
    function getRenderedPages(
      pages: PaginationData[],
    ): Array<PaginationData | null> {
      const MAX_PAGES = 7;
      if (pages.length <= MAX_PAGES) {
        return pages;
      }
      const firstPage = pages[0];
      const lastPage = pages[pages.length - 1];
      // first and last page are always rendered
      const middlePages = pages.slice(1, -1);
      // define which middle pages are rendered or should display "..."
      const MAX_RENDERED_ITEMS = 4;
      const diffActiveToFirst = activePage - 1;
      const diffActiveToLast = pages.length - activePage;
      if (diffActiveToFirst < MAX_RENDERED_ITEMS) {
        const renderedPages = middlePages.slice(0, MAX_RENDERED_ITEMS);
        return [firstPage, ...renderedPages, null, lastPage];
      }

      if (diffActiveToLast < MAX_RENDERED_ITEMS) {
        const lastItem = middlePages.length;
        const renderedPages = middlePages.slice(
          lastItem - MAX_RENDERED_ITEMS,
          lastItem,
        );
        return [firstPage, null, ...renderedPages, lastPage];
      }

      // activePage should be in the middle
      const activePageIdx = activePage - 1;
      return [
        firstPage,
        null,
        pages[activePageIdx - 1],
        pages[activePageIdx],
        pages[activePageIdx + 1],
        null,
        lastPage,
      ];
    }

    const pages: PaginationData[] =
      paginationData?.map((x, idx) => ({ ...x, pageId: idx + 1 })) ??
      Array.from(Array(numPages), (_, idx) => ({
        href: undefined,
        target: undefined,
        pageId: idx + 1,
      }));

    const renderedPages = getRenderedPages(pages);

    return (
      <HStack spacing={8}>
        <IconLink
          icon={interaction_arrows_chevronLeft}
          animationDirection="left"
          disabled={activePage === 1}
          iconTitle="Vorherige Seite"
          onClick={(e) => {
            onPageClick(activePage - 1, e);
          }}
        />
        <nav role="navigation" aria-label="Pagination Navigation">
          <NumbersWrapper>
            {renderedPages &&
              renderedPages.map((page, idx) => {
                const label = page ? page.pageId : '...';
                // ellipses placeholders should be disabled
                let props: LinkProps = {
                  disabled: true,
                };
                if (isLinkProps(page)) {
                  props = page;
                }
                const isActive = page ? activePage === page.pageId : false;
                const ariaLabel = !page
                  ? undefined
                  : page?.['aria-label']
                    ? page['aria-label']
                    : isActive
                      ? `Aktuelle Seite, Seite ${page.pageId}`
                      : page.pageId === numPages
                        ? `Zum letzten Seite, Seite ${page.pageId}`
                        : `Zu Seite ${page.pageId}`;
                return (
                  <PaginationButton
                    key={idx}
                    {...props}
                    label={label}
                    isActive={isActive}
                    aria-label={ariaLabel}
                    aria-hidden={page ? 'false' : 'true'}
                    onClick={(e) =>
                      !page
                        ? undefined
                        : onPageClick
                          ? onPageClick(page.pageId, e)
                          : props?.onClick
                            ? props.onClick(e)
                            : undefined
                    }
                  />
                );
              })}
          </NumbersWrapper>
        </nav>
        <IconLink
          iconPosition="left"
          icon={interaction_arrows_chevronRight}
          disabled={activePage === pages.length}
          iconTitle="Nächste Seite"
          onClick={(e) => onPageClick(activePage + 1, e)}
        />
      </HStack>
    );
  },
);

Pagination.displayName = 'Pagination';

const NumbersWrapper = styled('ul', {
  display: 'flex',
  gap: 8,
});

function isLinkProps(x: LinkProps | null): x is LinkProps {
  return x !== null;
}
