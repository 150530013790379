import {
  NeugelbSearchApiResult,
  NeugelbSuggestResults,
} from '@components/Search';
import { fetchUtil } from '@utils/helpers/fetchUtil';

type MoreLikeThisHit = {
  id: string;
  score: number;
  title: string;
  url: string;
};

type MoreLikeThis = {
  moreLikeThis: { hits: MoreLikeThisHit[] };
};

export const querySuggestionsApi = async ({
  url,
  signal,
  query,
}: {
  url: string;
  signal?: AbortSignal;
  query: string;
}) => {
  return fetchUtil<NeugelbSuggestResults>(
    url,
    undefined,
    {
      suggest: {
        query,
        limit: 5,
      },
    },
    'POST',
    { signal },
  );
};

export const queryResultsApi = async ({
  url,
  signal,
  query,
  limit,
  offset,
  fuzzy,
}: {
  url: string;
  signal?: AbortSignal;
  query: string;
  limit?: number;
  offset?: number;
  fuzzy?: boolean;
}) => {
  const filters: never[] = [];
  return fetchUtil<NeugelbSearchApiResult>(
    url,
    undefined,
    {
      search: {
        query,
        limit: limit ?? 10,
        offset,
        fuzzy,
        filters,
        includeProperties: ['semanticDocumentInfo'],
      },
      // one suggestion is used for "maybe you meant..."
      suggest: {
        query,
        limit: 1,
      },
    },
    'POST',
    { signal },
  );
};

export const queryMoreLikeThisApiWith = async ({
  url,
  moreLikeThisUrl,
  limit,
}: {
  url: string;
  moreLikeThisUrl: string;
  limit: number;
}) =>
  fetchUtil<MoreLikeThis>(
    url,
    undefined,
    {
      moreLikeThis: {
        like: {
          url: moreLikeThisUrl,
        },

        limit,
      },
    },
    'POST',
  );
