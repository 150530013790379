/* eslint-disable react/no-multi-comp */
import React from 'react';
import { VStack } from '@neui/layout';
import { Headline } from '@neui/styleguide-commerzbank';
import { styled } from '@neui/core';

import { widgetToTrackType } from '@utils/snowplowTracking';
import { INLINE_WIDGET_TYPES, WIDGET_TYPES } from '@utils/WidgetChecker';

import DirectionsWidget from './DirectionsWidget';
import GenericWidget from './GenericWidget';
import { ICallToAction } from './types';

const WidgetBox = ({
  headline,
  children,
}: {
  headline?: string;
  children: React.ReactNode;
}) => {
  return (
    <VStack spacing={24} alignItems="flex-start">
      {headline && (
        <Headline type="h4" renderAs={'span'}>
          {headline}
        </Headline>
      )}
      {children}
    </VStack>
  );
};

const SimpleWidget = ({
  widgetType,
  ctas,
  secondary,
}: {
  widgetType: INLINE_WIDGET_TYPES;
  ctas: ICallToAction[];
  secondary: boolean;
}): JSX.Element => {
  const widgetName = widgetToTrackType[widgetType];

  switch (widgetType) {
    case WIDGET_TYPES.DIRECTIONS:
      return (
        <WidgetBox headline={'Route zur Filiale'}>
          <DirectionsWidget
            ctas={ctas}
            secondary={secondary}
            widgetName={widgetName}
          />
        </WidgetBox>
      );

    case WIDGET_TYPES.GENERICS:
    case WIDGET_TYPES.ONLINEBANKING:
      return (
        <WidgetBox>
          <GenericWidget
            ctas={ctas}
            secondary={secondary}
            widgetName={widgetName}
          />
        </WidgetBox>
      );

    default:
      return <></>;
  }
};

SimpleWidget.displayName = 'SimpleWidget';

export const InlineWidgetRenderer = ({
  ctas,
  widgetsToRender,
}: {
  ctas: ICallToAction[];
  widgetsToRender: INLINE_WIDGET_TYPES[];
}): JSX.Element => {
  return (
    <>
      <InlineWidgetWrapper spacing={16} data-cy={'inline-widgets'}>
        {ctas &&
          widgetsToRender.map((w, i) => (
            <SimpleWidget
              key={i}
              widgetType={w}
              ctas={ctas}
              secondary={i > 0}
            />
          ))}
      </InlineWidgetWrapper>
    </>
  );
};

InlineWidgetRenderer.displayName = 'InlineWidgetRenderer';

const InlineWidgetWrapper = styled(VStack, {
  padding: 16,
});
