import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { HStack } from '@neui/layout';
import { styled } from '@neui/core';

import { navigate } from '@utils/navigate';
import { WidgetName, useTracker } from '@utils/snowplowTracking';
import { SearchCtx } from '@components/Search/CdsSearch';
import { Icon, IconProps } from '@components/neui-components/atoms/Icon';
import { dynamicHref } from '@utils/helpers/linkHelpers';
import { GA4TrackSearchResultInlineWidgetClick } from '@utils/tracking';

import { Button } from '../neui-components/atoms/Button';

export const WidgetButton = ({
  url,
  label,
  icon,
  secondary,
  widgetName,
}: {
  url?: string;
  label: string;
  icon?: IconProps['icon'];
  secondary?: boolean;
  widgetName: WidgetName;
}): React.ReactElement => {
  const { trackWidgetInteraction } = useTracker(WidgetButton.name);
  const router = useRouter();
  const searchContext = useContext(SearchCtx);
  const contexts = searchContext ? [searchContext] : [];

  if (!url) {
    return <></>;
  }

  return (
    <>
      <StyledButton
        href={dynamicHref(url)}
        type={secondary ? 'secondary' : undefined}
        onClick={(e) => {
          e?.preventDefault();
          e?.stopPropagation();
          trackWidgetInteraction?.(widgetName, 'cta', url, contexts);
          GA4TrackSearchResultInlineWidgetClick(label, url);
          void navigate(url, router);
        }}
      >
        <HStack spacing={8} alignItems={'center'} css={{ width: '100%' }}>
          {icon && <Icon icon={icon} size={'small'} />}
          {label}
        </HStack>
      </StyledButton>
    </>
  );
};

const StyledButton = styled(Button, {
  textAlign: 'center !important',
  '@xxs': {
    alignSelf: 'stretch',
  },
  '@sm': {
    alignSelf: 'flex-start',
  },
});
