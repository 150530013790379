import { GetStaticPropsContext } from 'next';
import getConfig from 'next/config';
import { GetStaticPropsResult } from 'next/types';

import {
  ArticlePageProps,
  CategoryPageProps,
  SelfServicePageProps,
} from 'pages/[...slug]';
import allConstants from '@utils/constants.json';
import { IndexPageProps, MostReadArticleType, NewsDataType } from 'pages';
import { WidgetRedirectProps } from 'pages/widget';
import { shorten } from '@components/Search/helpers';

import { slashify } from './helpers/linkHelpers';

export type Basename = 'cds-wissen' | 'cds-wissen-gpp-de' | 'cds-wissen-gpp-en';
export type DeploymentStage = 'dev' | 'test' | 'prod';

type SiteConstants = (typeof allConstants)[Basename];

export type Metadata = {
  basename: Basename;
  constants: SiteConstants;
  isGpp: boolean;
  isGppDE: boolean;
  searchUrl: string;
  deploymentStage: DeploymentStage;
};

type PathsType = {
  params: {
    slug: string;
  };
};

const searchUrl = process.env.NEXT_PUBLIC_SEARCH_URL as string;
const basename = process.env.NEXT_PUBLIC_WEBAPP_BASENAME as Basename;
const constants = allConstants[basename];
export const isGpp = basename.includes('gpp');
export const isGppDE = basename.includes('gpp-de');
const deploymentStage = (process.env.DEPLOYMENT_STAGE ??
  'prod') as DeploymentStage;
const metadata: Metadata = {
  basename,
  constants,
  isGpp,
  isGppDE,
  searchUrl,
  deploymentStage,
};

export function isCategoryProps(
  props: ArticlePageProps | CategoryPageProps,
): props is CategoryPageProps {
  return props && props.hasOwnProperty('hasSubCategories');
}
export class DataFetcher {
  private staticData: any;

  constructor() {
    const {
      serverRuntimeConfig: { staticData },
    } = getConfig();

    this.staticData = staticData;
  }

  getNews(): NewsDataType[] {
    if (isGpp) {
      return [];
    }
    return this.staticData.newsData.entities;
  }
  getMostReadArticles(): MostReadArticleType[] {
    if (isGppDE) {
      return [
        {
          title: 'Wie aktiviere ich das photoTAN-Verfahren?',
          slug: '/wie-aktiviere-ich-das-phototan-verfahren/',
          excerpt:
            'Sobald Sie den Aktivierungsbrief mit Ihrer persönlichen Aktivierungsgrafik erhalten haben, können Sie zusammen mit dem Lesegerät oder der Smartphone-A...',
        },
        {
          title: 'Wie kann ich meine Berechtigungen aktualisieren?',
          slug: '/wie-kann-ich-meine-berechtigungen-aktualisieren/',
          excerpt:
            'Ihre Teilnehmerberechtigungen werden in Global Payment Plus über einen neuen Abruf der Berechtigungen aktualisiert, den Sie wie folgt vornehmen. Hierb...',
        },
        {
          title:
            'Wie erhalte ich den Initialisierungsbrief für die Freischaltung meines ersten Bankzugangs?',
          slug: '/wie-erhalte-ich-den-initialisierungsbrief-fuer-die-freischaltung-meines-ersten-bankzugangs/',
          excerpt:
            'Um Global Payment Plus nutzen zu können, ist als Erstes die Freischaltung Ihres Commerzbank Bankzugangs vorzunehmen. Es handelt sich hierbei um Ihren ...',
        },
      ];
    } else {
      return [
        {
          title: 'How do I change the language in Global Payment Plus?',
          slug: '/how-do-i-change-the-language-in-global-payment-plus/',
          excerpt:
            'You can change the language settings used in Global Payment Plus via the Corporate Banking Portal. Follow the steps below to change the language. Ther...',
        },
        {
          title:
            'How can I request an activation letter for my photoTAN reader / app?',
          slug: '/how-can-i-request-an-activation-letter-for-my-phototan-reader-app/',
          excerpt:
            'The activation letter is necessary in order to enable the "photoTAN" reader / app. If you have not received a letter or do not have your activation le...',
        },
        {
          title: 'How do I activate photoTAN?',
          slug: '/how-do-i-activate-phototan/',
          excerpt:
            'Activation of photoTAN can be done either by using the photoTAN reader or by using the photoTAN app but only once you have received the activation let...',
        },
      ];
    }
  }

  getMostSearchedTerms(): string[] {
    if (isGpp) {
      if (isGppDE)
        return [
          'kontoauszug',
          'global payment plus',
          'aval',
          'ebics',
          'überweisung',
          'initialisierungsbrief',
          'corporate card',
        ];
      else
        return [
          'change language',
          'photoTAN',
          'global payment plus',
          'address',
        ];
    }

    const topSearchQueries = this.staticData.gcpData.topSearchQueries.map(
      (terms: any) => terms.search_text,
    );

    let charCount = 0;
    const mostSearchedTerms = topSearchQueries.map((query: string) => {
      charCount += query.length;
      // limit the character count to avoid line breaking
      if (charCount > 106) {
        return;
      }
      return query;
    });
    return mostSearchedTerms.filter((term: string | undefined) => term);
  }

  getPageMetadata(): Metadata {
    return metadata;
  }

  getAllProps(): SelfServicePageProps[] {
    return this.staticData.sabioData.entities;
  }

  getPaths(): PathsType[] {
    // Be aware of sub-paths.
    // TODO: check with Felix about this
    // const mapFn = (x: string) => ({
    //   params: { slug: x.split('/').filter((idx) => idx) },
    // });

    return this.staticData.sabioData.paths;
  }

  getProps(
    ctx: GetStaticPropsContext,
  ): GetStaticPropsResult<SelfServicePageProps> {
    const pageSlug = ctx?.params?.slug && ctx.params.slug[0];
    const props = this.staticData.sabioData.entities.filter(
      (x: any) => slashify(x.slug.slice(1)) === slashify(pageSlug),
    );
    const mostSearchedTerms = this.getMostSearchedTerms();

    if (props && props.length) {
      return {
        props: {
          ...props[0],
          metadata,
          mostSearchedTerms,
        },
      };
    }

    console.log(`${pageSlug} has no props`);
    return {
      notFound: true,
    };
  }

  getIndexProps(): GetStaticPropsResult<IndexPageProps> {
    const entities = this.staticData.sabioData.entities;
    const indexData = entities.find((entity: any) => entity.slug === '/');
    const gcpArticlesUrls: string[] = isGpp
      ? []
      : this.staticData.gcpData.topArticles.map(
          (articleUrl: { page_url: string }) => articleUrl.page_url,
        );

    const mostSearchedTerms = this.getMostSearchedTerms();
    const newsData = this.getNews();

    const mostReadArticles = isGpp
      ? this.getMostReadArticles()
      : gcpArticlesUrls.slice(0, 3).map((url) => {
          const article = entities.find((e: any) => {
            const slug = slashify(e.slug);
            return slug !== '/' && url.includes(slug);
          });

          if (article === undefined) {
            throw Error(`Most read article "${url}" not found`);
          }

          return {
            title: article.title,
            slug: slashify(article.slug),
            excerpt: shorten(article.excerpt, 80),
          };
        });

    return {
      props: {
        title: indexData.title,
        topCategories: indexData.subentries,
        mostReadArticles,
        mostSearchedTerms,
        metadata,
        newsData,
      },
    };
  }

  getWidgetRedirectProps(): GetStaticPropsResult<WidgetRedirectProps> {
    return {
      props: {},
    };
  }
}
