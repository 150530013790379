import { AsProps, css, CSSProps, styled, VariantProps } from '@neui/core';
import { Flex, VStack } from '@neui/layout';
import { Typography, Text } from '@neui/styleguide-commerzbank';
import { AnchorHTMLAttributes, forwardRef, HTMLAttributes } from 'react';

import { Icon, IconProps } from '../atoms/Icon';

export type ActionButtonProps = (
  | HTMLAttributes<HTMLButtonElement>
  | AnchorHTMLAttributes<HTMLAnchorElement>
) &
  VariantProps<typeof ThumbnailWrapper> &
  CSSProps &
  AsProps &
  Pick<IconProps, 'variant' | 'icon'> & {
    href?: string;
    onClick?: (e?: MouseEvent) => void;
    label: string;
    helperText: string;
  };

export const ThumbnailWrapper = styled(Flex, {
  borderRadius: '50%',
  display: 'inline-flex',
  aspectRatio: '1 / 1',
  transition: 'all 300ms ease-in-out',
  overflow: 'hidden',
  '&:not(:disabled)': {
    cursor: 'pointer',
  },
  variants: {
    look: {
      primary: {
        margin: 6,
        backgroundColor: '$colors$primary',
        boxShadow:
          '0 0 0 6px $colors$primary, 0 0 0 0.5px $colors$primary, 0 0 0 2px $colors$primary, 0 0 0 1px $colors$primary',
        // boxShadow:
        //   '0 0 0 6px var(--lsg-color-col-action-primary-light,#FFD700), 0 0 0 0.5px var(--lsg-color-col-action-primary-hover-light,#FFC700), 0 0 0 2px var(--lsg-color-col-action-primary-light,#FFD700), 0 0 0 1px var(--lsg-color-col-action-primary-light,#FFD700)',
        '&:hover': {
          boxShadow:
            '0 0 0 6px $colors$primary-hover, 0 0 0 0.5px $colors$primary-hover, 0 0 0 2px $colors$primary-hover, 0 0 0 1px $colors$primary-hover',
          backgroundColor: '$colors$primary-hover',
        },
        '&:active': {
          boxShadow:
            '0 0 0 0 $colors$primary-hover, 0 0 0 0 $colors$primary-hover, 0 0 0 0 $colors$primary-hover, 0 0 0 0 $colors$primary-hover',
        },
      },
      secondary: {
        size: 48,
        border: '1px solid $colors$secondary',
        '&:hover': {
          border: '1px solid $colors$secondary-hover',
          boxShadow: '0px 0px 0px 1px inset $colors$secondary-hover',
        },
        '&:active': {
          border: '1px solid $colors$secondary-hover',
          boxShadow: 'inset 0px 0px 0px 3px $colors$secondary-hover',
        },
      },
    },
    disabled: {
      true: {
        cursor: 'default !important',
        pointerEvents: 'none',
        border: '1px solid $colors$disabled',
        [`& *`]: {
          color: '$colors$disabled !important',
          fill: '$colors$disabled !important',
        },
        [`& ~ *`]: {
          color: '$colors$disabled !important',
        },
      },
    },
  },
});

const buttonStyles = css({
  textDecoration: 'none',
  display: 'flex',
  height: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 16,
  background: 'none',
  color: 'inherit',
  border: 'none',
  padding: 0,
  font: 'inherit',
  outline: 'inherit',
  '&:focus-visible': {
    outline: '2px solid $text-standard',
    outlineOffset: 4,
    border: 'none',
    borderRadius: 30,
  },
});

export const CircleIconLink = forwardRef<
  HTMLButtonElement | AnchorHTMLAttributes<HTMLAnchorElement>,
  ActionButtonProps
>(
  (
    {
      href,
      disabled,
      renderAs: Component = !disabled && href ? 'a' : 'button',
      label,
      helperText,
      icon,
      variant = 'outline',
      look = 'primary',
      ...rest
    },
    forwardedRef,
  ) => {
    const StyledComponent = styled(Component, buttonStyles);
    return (
      <StyledComponent
        {...rest}
        ref={forwardedRef}
        href={href}
        disabled={disabled}
      >
        <ThumbnailWrapper
          alignItems="center"
          justifyContent="center"
          look={look}
          disabled={disabled}
        >
          <Icon icon={icon} variant={variant} size="small" />
        </ThumbnailWrapper>
        <VStack>
          <Typography size={5} weight={'medium'}>
            {label}
          </Typography>
          <Text type="helper">{helperText}</Text>
        </VStack>
      </StyledComponent>
    );
  },
);

CircleIconLink.displayName = 'CircleIconLink';
