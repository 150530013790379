import { location___direction } from '@neui/styleguide-commerzbank';
import React from 'react';

import { WidgetName } from '@utils/snowplowTracking';
import { isDirections } from '@utils/WidgetChecker';

import { ICallToAction } from './types';
import { WidgetButton } from './WidgetButton';

const DirectionsWidget = ({
  ctas,
  secondary,
  widgetName,
}: {
  ctas: Array<ICallToAction>;
  secondary?: boolean;
  widgetName: WidgetName;
}): JSX.Element => {
  const directions = ctas.find((x) => isDirections(x));
  if (!directions) {
    return <></>;
  }

  const { url, cta } = directions;

  return (
    <>
      <WidgetButton
        label={cta.trim()}
        url={url}
        icon={location___direction}
        secondary={secondary}
        widgetName={widgetName}
      />
    </>
  );
};

export default DirectionsWidget;
